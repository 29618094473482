import { Injectable } from '@angular/core';

import { UserRole } from '../../enums/user-role';
import { EntityValidationErrors } from '../../models/app-error';
import { OrganizationEditForm } from '../../models/forms/organization-edit-form';
import { User } from '../../models/user';
import { Organization, EditOrganization } from '../../models/organization';

import { EditOrganizationDto, OrganizationDto } from './dto/organization.dto';
import { AppValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';
import { OrganizationUserRolesMapper } from './organization-user-roles.mapper';
import { EditOrganizationUserPermissionsDto } from './dto/organization-user-permissions.dto';

/** Organization mapper. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationMapper
implements
  IMapperFromDto<OrganizationDto, Organization>,
  IMapperToDto<EditOrganizationDto, EditOrganization>,
  IValidationErrorMapper<EditOrganizationDto, OrganizationEditForm> {

  public constructor(
    private readonly userRolesMapper: OrganizationUserRolesMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: OrganizationDto, id?: number): Organization {
    return new Organization({
      id: dto?.id ?? id ?? 0,
      name: dto.name,
      city: dto.city,
      state: dto.state,
      country: dto.country,
      phone: dto.phone,
      address: dto.address,
      zip: dto.zip,
      fax: dto.fax,
      mailingAddress: dto.mailingAddress,
      mailingCity: dto.mailingCity,
      mailingState: dto.mailingState,
      mailingZip: dto.mailingZip,
      primaryEmail: dto.primaryEmail,
      isActive: !dto.removedAt,
      logoUrl: dto.logoDownloadUrl?.url ?? '',
    });
  }

  /** @inheritdoc */
  public toDto(data: EditOrganization): EditOrganizationDto {
    return {
      name: data.name,
      address: data.address,
      city: data.city,
      country: data.country,
      state: data.state,
      zip: data.zip,
      phone: data.phone,
      fax: data.fax,
      mailingAddress: data.mailingAddress,
      mailingCity: data.mailingCity,
      mailingState: data.mailingState,
      mailingZip: data.mailingZip,
      primaryEmail: data.primaryEmail,
    };
  }

  /**
   * Create edit DTO for organization user permissions.
   * @param data User data.
   * @param userRoles User roles.
   */
  public toOrganizationEditUserDto(data: User, userRoles: UserRole[]): EditOrganizationUserPermissionsDto {
    const userRoleDto = this.userRolesMapper.toDto(userRoles);
    return {
      userId: data.id,

      // TODO (Popova K.): Change when API is ready.
      // Probably create a mapper similar to ProjectUserPermissionsMapper.
      ...userRoleDto,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: AppValidationErrorDto<OrganizationDto>,
  ): EntityValidationErrors<OrganizationEditForm> {
    return {
      name: extractErrorMessage(errorDto.name),
      address: extractErrorMessage(errorDto.address),
      city: extractErrorMessage(errorDto.city),
      country: extractErrorMessage(errorDto.country),
      state: extractErrorMessage(errorDto.state),
      zip: extractErrorMessage(errorDto.zip),
      phone: extractErrorMessage(errorDto.phone),
      fax: extractErrorMessage(errorDto.fax),
      mailingAddress: extractErrorMessage(errorDto.mailingAddress),
      mailingCity: extractErrorMessage(errorDto.mailingCity),
      mailingState: extractErrorMessage(errorDto.mailingState),
      mailingZip: extractErrorMessage(errorDto.mailingZip),
    };
  }

  /** @inheritdoc */
  public validationErrorLogoImageFileFromDto(
    errorDto: AppValidationErrorDto<{ readonly logoImageFile: string; }>,
  ): EntityValidationErrors<OrganizationEditForm> {
    return {
      logoImageFile: extractErrorMessage(errorDto.logoImageFile),
    };
  }
}
