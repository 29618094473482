import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { ProjectUserRole } from '@atsdart/common/core/enums/project-user-role';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';
import { controlProviderFor, SimpleValueAccessor } from '@atsdart/common/core/utils/value-accessor';

/** User project roles radio buttons. */
@Component({
  selector: 'atsdartw-user-project-roles-radio-buttons',
  templateUrl: './user-project-roles-radio-buttons.component.html',
  styleUrls: [
    './user-project-roles-radio-buttons.component.css',
    '../user-roles-select/user-roles-select.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => UserProjectRolesRadioButtonsComponent)],
})
@Destroyable()
export class UserProjectRolesRadioButtonsComponent extends SimpleValueAccessor<ProjectUserRole | null> implements OnInit {
  /** Project User role. */
  protected readonly projectUserRole = ProjectUserRole;

  /** User role form control. */
  protected readonly userRoleControl = this.fb.control<ProjectUserRole | null>(null);

  public constructor(
    private readonly fb: NonNullableFormBuilder,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: ProjectUserRole | null): void {
    this.userRoleControl.setValue(value, { emitEvent: false });
    super.writeValue(value);
  }

  private subscribeToControlChanges(): void {
    this.userRoleControl.valueChanges.pipe(
      takeUntilDestroy(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }
}
