import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable, map, first } from 'rxjs';

import { UserRole } from '../enums/user-role';
import { UserProfile } from '../models/user-profile';
import { UserAuthService } from '../services/user-auth.service';
import { routePaths } from '../utils/route-paths';

/** Guard prevents a current user from accessing Admin Panel if he doesn't have necessary permissions. */
@Injectable({
  providedIn: 'root',
})
export class AdminPanelGuard implements CanLoad, CanActivate {
  public constructor(
    private readonly userService: UserAuthService,
    private readonly router: Router,
  ) { }

  /** @inheritdoc */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  /** Determine if route could be achieved. */
  public canActivate(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  private canNavigate(): Observable<boolean | UrlTree> {
    return this.userService.currentUser$.pipe(
      map(user => this.guard(user)),
      first(),
    );
  }

  private guard(user: UserProfile | null): boolean | UrlTree {
    if (user !== null && (user.isGlobalAdmin === true || this.checkUserHasAllowedRole(user))) {
      return true;
    }
    return this.router.createUrlTree(routePaths.accessError);
  }

  private checkUserHasAllowedRole(user: UserProfile): boolean {
    const allowedRoles = [UserRole.OrganizationAdmin, UserRole.UserAdmin, UserRole.Developer];
    return user.organizations.some(org => org.roles.some(role => allowedRoles.includes(role)));
  }
}
