import { listenControlChanges } from '@atsdart/common/core/utils/rxjs/listen-control-changes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NonNullableFormBuilder, ValidationErrors } from '@angular/forms';
import { DEFAULT_ORGANIZATION_LOGO } from '@atsdart/common/core/utils/constants';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';
import { SimpleValueAccessor, controlProviderFor } from '@atsdart/common/core/utils/value-accessor';
import { Observable, ReplaySubject, combineLatest, finalize, map, of, switchMap, first, catchError, EMPTY } from 'rxjs';
import { ValidationErrorCode } from '@atsdart/common/core/models/validation-error-code';

import { getCheckedImageUrl } from '../organization-logo/get-checked-image-url';

/** Logo image input. */
@Destroyable()
@Component({
  selector: 'atsdartw-logo-image-input',
  templateUrl: './logo-image-input.component.html',
  styleUrls: ['./logo-image-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => LogoImageInputComponent)],
})
export class LogoImageInputComponent extends SimpleValueAccessor<File | null> {

  /** Logo image URL. */
  @Input()
  public set logoImageUrl(value: string) {
    this.logoImageUrl$.next(value);
  }

  /** Is loading. */
  @Input()
  public isLoading: boolean | null = false;

  /** Validation errors. */
  @Input()
  public errors: ValidationErrors | null = null;

  /** Emitted when delete file. */
  @Output()
  public readonly deleteFile = new EventEmitter<void>();

  /** File control. */
  protected readonly fileControl = this.fb.control<File | null>(null);

  private readonly previewImageUrl$ = this.createPreviewImageUrl();

  /** Logo image URl stream. */
  protected readonly logoImageUrl$ = new ReplaySubject<string>(1);

  /** Logo image source path stream. */
  protected readonly imageSourcePath$ = this.createImageSourcePathStream();

  /** File types to allow. */
  protected readonly allowedFileTypes = ['image/png', 'image/jpeg'];

  /** This flag's needed to check if the file exists on backend or it's loaded via input. */
  protected readonly hasFile$ = this.createHasFileStream();

  /** Logo image file name. */
  protected get logoFileName(): string {
    return this.fileControl.value?.name ?? '';
  }

  public constructor(
    cdr: ChangeDetectorRef,
    private readonly fb: NonNullableFormBuilder,
  ) {
    super(cdr);
  }

  /** @inheritdoc */
  public override writeValue(value: File | null): void {
    this.fileControl.setValue(value, { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritDoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fileControl.disable();
    } else {
      this.fileControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** Handle click on delete file button. */
  protected onDeleteFileClick(): void {
    this.fileControl.reset();
    this.deleteFile.emit();
  }

  /**
   * Update file control.
   * @param value File.
   */
  protected updateFileControl(value: File): void {
    this.updateFileControlWithValidation(value).pipe(
      takeUntilDestroy(this),
    )
      .subscribe(() => {
        if (this.fileControl.valid) {
          this.controlValue = value;
        }
      });
  }

  private updateFileControlWithValidation(file: File): Observable<string> {
    this.fileControl.patchValue(file);
    const url = URL.createObjectURL(file);

    return getCheckedImageUrl(url).pipe(
      first(),
      catchError((error: unknown) => {
        this.fileControl.setErrors({
          [ValidationErrorCode.AppError]: {
            message: (error as Error).message,
          },
        });
        this.changeDetectorRef.markForCheck();
        return EMPTY;
      }),
      finalize(() => URL.revokeObjectURL(url)),
    );
  }

  private createHasFileStream(): Observable<boolean> {
    return combineLatest([
      listenControlChanges(this.fileControl),
      this.logoImageUrl$,
    ]).pipe(
      map(([file, imageUrl]) => Boolean(file ?? imageUrl)),
    );
  }

  private createPreviewImageUrl(): Observable<string> {
    return listenControlChanges(this.fileControl).pipe(
      switchMap(file => {
        if (file != null) {
          const url = URL.createObjectURL(file);
          const previewImageUrl$ = new Observable<string>(subscription => subscription.next(url));
          return previewImageUrl$.pipe(
            finalize(() => URL.revokeObjectURL(url)),
          );
        }

        return of('');
      }),
    );
  }

  private createImageSourcePathStream(): Observable<string> {
    return combineLatest([this.logoImageUrl$, this.previewImageUrl$]).pipe(
      map(([logoImageUrl, previewImageUrl]) => {
        if (previewImageUrl !== '') {
          return previewImageUrl;
        }

        return logoImageUrl || DEFAULT_ORGANIZATION_LOGO;
      }),
      switchMap(url => getCheckedImageUrl(url)),
    );
  }
}
