import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, OnInit, inject } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';

/** Directive to enable fixed scroll for autocomplete.  */
@Destroyable()
@Directive({
  selector: 'mat-autocomplete[atsdartcFixedScroll]',
})
export class AutocompleteFixedScrollDirective implements OnInit {

  private readonly document = inject(DOCUMENT);

  private readonly matAutocomplete = inject(MatAutocomplete);

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly wrapperCssClassName = 'no-scroll-wrapper';

  private get scrollableParent(): HTMLElement | null {
    return this.elementRef.nativeElement.closest('[cdkscrollable]');
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.matAutocomplete.opened.pipe(
      takeUntilDestroy(this),
    ).subscribe(() => {
      const div = this.document.createElement('div');
      div.classList.add('cdk-overlay-backdrop', this.wrapperCssClassName);
      this.scrollableParent?.prepend(div);
    });

    this.matAutocomplete.closed.pipe(
      takeUntilDestroy(this),
    ).subscribe(() => {
      this.scrollableParent?.querySelector(`.${this.wrapperCssClassName}`)?.remove();
    });
  }
}
