<form>
  <label class="form-field">
    <div class="form-field__label">Logo</div>
    <atsdartw-organization-logo
      class="form-field__image"
      [imageSourcePath]="(imageSourcePath$ | async) ?? ''"
    />
    <div class="form-field-container">
      <div class="manage-file">
        <mat-form-field class="file-upload-field">
          <atsdartw-uploading-file
            [allowedFileTypes]="allowedFileTypes"
            [ngModel]="fileControl.value"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateFileControl($event)"
          />
          <mat-hint class="form-field__hint">
            PNG or JPG image, recommended size = 120 x 120px
          </mat-hint>
        </mat-form-field>
        <button
          *ngIf="(hasFile$ | async) && !fileControl.disabled"
          mat-flat-button
          type="reset"
          color="primary"
          class="file-upload__button"
          [atsdartcLoading]="isLoading"
          (click)="onDeleteFileClick()"
        >
          Delete Logo
        </button>
      </div>
      <mat-error
        *ngIf="errors || fileControl.errors as errorsMessage"
        class="form-field__error"
      >
        <atsdartc-validation-message [errors]="errorsMessage" />
      </mat-error>
    </div>
  </label>
</form>
