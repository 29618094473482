import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@atsdart/common/shared/common-shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WebAppLayoutComponent } from './components/web-app-layout/web-app-layout.component';
import { NavListItemComponent } from './components/nav-list-item/nav-list-item.component';
import { AppToolbarComponent } from './components/app-toolbar/app-toolbar.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AppAccessErrorPageComponent } from './components/app-access-error-page/app-access-error-page.component';
import { MembersTableFiltersComponent } from './components/members-table-filters/members-table-filters.component';
import { MembersTableComponent } from './components/members-table/members-table.component';
import { UserRolesSelectComponent } from './components/user-roles-select/user-roles-select.component';
import { UserProjectRolesRadioButtonsComponent } from './components/user-project-roles-radio-buttons/user-project-roles-radio-buttons.component';
import { ValuesListControlComponent } from './components/values-list-control/values-list-control.component';
import { LogoImageInputComponent } from './components/logo-image-input/logo-image-input.component';
import { TagsSelectComponent } from './components/tags-select/tags-select.component';
import { ConfirmationCardComponent } from './components/confirmation-card/confirmation-card.component';
import { OrganizationLogoComponent } from './components/organization-logo/organization-logo.component';
import { EmptyComponent } from './empty/empty.component';
import { UserRolesRadioButtonsComponent } from './user-roles-radio-buttons/user-roles-radio-buttons.component';
import { DownloadDialogComponent } from './components/download-dialog-component/download-dialog.component';
import { UploadingFileComponent } from './components/uploading-file/uploading-file.component';

/** Module that contains entities shared between features/layouts. */
@NgModule({
  declarations: [
    WebAppLayoutComponent,
    NavListItemComponent,
    AppToolbarComponent,
    ConfirmDialogComponent,
    AppAccessErrorPageComponent,
    MembersTableFiltersComponent,
    MembersTableComponent,
    UserRolesSelectComponent,
    UserProjectRolesRadioButtonsComponent,
    ValuesListControlComponent,
    LogoImageInputComponent,
    TagsSelectComponent,
    ConfirmationCardComponent,
    OrganizationLogoComponent,
    EmptyComponent,
    UserRolesRadioButtonsComponent,
    DownloadDialogComponent,
    UploadingFileComponent,
  ],
  exports: [
    WebAppLayoutComponent,
    ConfirmDialogComponent,
    MembersTableFiltersComponent,
    MembersTableComponent,
    UserRolesSelectComponent,
    UserProjectRolesRadioButtonsComponent,
    ValuesListControlComponent,
    LogoImageInputComponent,
    FormsModule,
    TagsSelectComponent,
    ConfirmationCardComponent,
    OrganizationLogoComponent,
    EmptyComponent,
    UserRolesRadioButtonsComponent,
    DownloadDialogComponent,
    UploadingFileComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonSharedModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
  ],
})
export class WebSharedModule { }
