import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { AdminPanelGuard } from '@atsdart/common/core/guards/admin-panel.guard';
import { RestrictUserRolesGuard } from '@atsdart/common/core/guards/restrict-user-role.guard';
import { UnauthorizedGuard } from '@atsdart/common/core/guards/unauthorized.guard';

import { AppAccessErrorPageComponent } from './features/shared/components/app-access-error-page/app-access-error-page.component';
import { WebAppLayoutComponent } from './features/shared/components/web-app-layout/web-app-layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [UnauthorizedGuard, AdminPanelGuard],
    component: WebAppLayoutComponent,
    children: [
      {
        path: 'authority-management',
        loadChildren: () => import('./features/authority-management/authority-management.module').then(m => m.AuthorityManagementModule),
        canActivate: [RestrictUserRolesGuard],
        data: {
          userRoles: [UserRole.UserAdmin, UserRole.OrganizationAdmin],
          breadcrumb: {
            label: 'Authority Management',
          },
        },
      },
      {
        path: 'organizations',
        loadChildren: () => import('./features/organizations/organizations.module').then(m => m.OrganizationsModule),
        canActivate: [RestrictUserRolesGuard],
        data: {
          breadcrumb: 'Organizations',
        },
      },
      {
        path: 'organization-account-settings',
        loadChildren: () => import('./features/org-account-settings/org-account-settings.module').then(m => m.OrgAccountSettingsModule),
        canActivate: [RestrictUserRolesGuard],
        data: {
          userRoles: [UserRole.UserAdmin, UserRole.OrganizationAdmin],
          breadcrumb: {
            label: 'Organization Account Settings',
          },
        },
      },
      {
        path: 'resources',
        loadChildren: () => import('./features/components/components.module').then(m => m.ComponentsModule),
        data: {
          breadcrumb: {
            label: 'Resources',
          },
        },
      },
      {
        path: 'template-admin',
        loadChildren: () => import('./features/template-admin/template-admin.module').then(m => m.TemplateAdminModule),
        data: {
          breadcrumb: {
            label: 'Template Admin',
          },
        },
      },
      { path: '', redirectTo: 'authority-management', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./features/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'personal',
    loadChildren: () => import('./features/confirm-new-email/confirm-new-email.module').then(m => m.ConfirmNewEmailModule),
  },
  {
    path: 'access-error',
    component: AppAccessErrorPageComponent,
  },
  { path: '**', redirectTo: 'authority-management' },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
