import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { Form } from '@atsdart/common/core/models/form';
import { uuidv4 } from '@atsdart/common/core/utils/generate-uuid';
import { trackByIndex } from '@atsdart/common/core/utils/trackby';
import { controlProviderFor, SimpleValueAccessor } from '@atsdart/common/core/utils/value-accessor';

type OptionEditForm = Form<{

  /** Selected ID to edit. 'null' is necessary for new item. */
  readonly id: string | null;

  /** Option value. */
  readonly optionValue: string;
}>;

/** Values list control. */
@Component({
  selector: 'atsdartw-values-list-control',
  templateUrl: './values-list-control.component.html',
  styleUrls: ['./values-list-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => ValuesListControlComponent)],
})
export class ValuesListControlComponent extends SimpleValueAccessor<string[]> {

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** Edit form. */
  protected readonly editForm = this.createEditForm();

  /** Options map. */
  protected readonly optionsMap = new Map<string, string>();

  public constructor(
    cdr: ChangeDetectorRef,
    private readonly fb: NonNullableFormBuilder,
  ) {
    super(cdr);
  }

  /** @inheritdoc */
  public override writeValue(value: string[] | null): void {
    this.optionsMap.clear();
    for (const option of (value ?? [])) {
      this.optionsMap.set(uuidv4(), option);
    }
    super.writeValue(value);
  }

  /** Save option. */
  protected saveOption(): void {
    this.editForm.markAllAsTouched();
    const { id, optionValue } = this.editForm.getRawValue();

    if (this.editForm.invalid || optionValue.trim() === '') {
      return;
    }

    this.optionsMap.set(
      id ?? uuidv4(),
      optionValue,
    );

    this.editForm.reset();
    this.updateControlValue();
  }

  /**
   * Toggle edit by id.
   * @param id Item id.
   */
  protected toggleEdit(id: string): void {
    if (this.editForm.value.id === id) {
      this.editForm.reset();
      return;
    }

    const optionValue = this.optionsMap.get(id);

    if (optionValue) {
      this.editForm.patchValue({
        id,
        optionValue,
      });
    }
  }

  /**
   * Remove chip by id.
   * @param id Item id.
   */
  protected removeAt(id: string): void {
    this.optionsMap.delete(id);
    if (this.editForm.value.id === id) {
      this.editForm.reset();
    }
    this.updateControlValue();
  }

  private createEditForm(): FormGroup<OptionEditForm> {
    return this.fb.group<OptionEditForm>({
      id: this.fb.control(null),
      optionValue: this.fb.control(''),
    });
  }

  private updateControlValue(): void {
    this.controlValue = [...this.optionsMap.values()];
  }
}
