<atsdartc-chips-list-with-suggested
  *ngIf="listManager.pagePagination$ | async as pagination"
  placeholder="Add Tags"
  suggestedText="Suggested Tags:"
  [optionsList]="options$ | async"
  [formControl]="formControl"
  [isLoading]="listManager.listLoading$ | async"
  (searchChange)="onSearchChange($event)"
  (goToNextPage)="listManager.nextPage()"
/>
