import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Directive to mark ng-container as table-cell.
 * @example
 * ```html
 * <ng-container [atsdartcTableCell]="id">
 *   <ng-template #id let-row>
 *     {{ row.id }}
 *   </ng-template>
 * </ng-container>
 * ```
 *
 * Also we can use get some values from ngTemplateOutletContext:
 * @example
 * ```html
 * <ng-template #id let-row let-index="index">
 *   ...
 * </ng-template>
 * ```
 */
@Directive({
  selector: '[atsdartcTableCell]',
})
export class TableCellDirective {
  /** Template. */
  public template?: TemplateRef<unknown>;

  /** Template input. */
  @Input()
  public set atsdartcTableCell(value: TemplateRef<unknown>) {
    this.template = value;
  }
}
