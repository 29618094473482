import { Injectable } from '@angular/core';

import { OrganizationUser } from '../../models/organization-user';

import { DateMapper } from './date-mapper';
import { OrganizationUserDto } from './dto/user.dto';
import { IMapperFromDto } from './mappers';
import { ProjectUserPermissionsMapper } from './project-user-permissions.mapper';
import { OrganizationUserRolesMapper } from './organization-user-roles.mapper';

/** Organization user mapper. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationUserMapper implements IMapperFromDto<OrganizationUserDto, OrganizationUser> {
  public constructor(
    private readonly dateMapper: DateMapper,
    private readonly organizationUserRoleMapper: OrganizationUserRolesMapper,
    private readonly projectUserPermissionsMapper: ProjectUserPermissionsMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: OrganizationUserDto): OrganizationUser {
    return new OrganizationUser({
      id: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      createdAt: this.dateMapper.getDateWithoutTimezone(dto.createdAt),
      organizationRoles: this.organizationUserRoleMapper.fromDto(dto),
      projectPermissions: dto.projectAccess ? this.projectUserPermissionsMapper.fromDto(dto.projectAccess) : null,
    });
  }
}
