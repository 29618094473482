import {
  ErrorStateMatcher,
  mixinColor,
  mixinDisabled,
  mixinErrorState,
  mixinTabIndex,
} from '@angular/material/core';
import {
  ChangeDetectorRef,
  Directive,
  DoCheck,
  ElementRef,
  forwardRef,
  ForwardRefFn,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Provider,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';

import { BaseValueAccessor } from './value-accessor';

/**
 * Create provider for a MatFormField control.
 * @param factory Factory providing a reference to control component class.
 */
export function matFormFieldControlProviderFor(factory: ForwardRefFn): Provider {
  return {
    provide: MatFormFieldControl,
    useExisting: forwardRef(factory),
  };
}

/** Args for create base mat form control mixin. */
// "unknown" is used because we need to pass the type of the generic, but we cannot pass it mixins.
abstract class BaseMatFormFieldValueAccessor extends BaseValueAccessor<unknown> {
  /** State changes. */
  // eslint-disable-next-line rxjs/finnish, rxjs/no-exposed-subjects
  public readonly stateChanges = new Subject<void>();

  /** Angular control. */
  // We use "as" in this class because the angular-material does not take into that fields can be 'null'.
  public readonly ngControl = inject(NgControl, { optional: true, self: true }) as NgControl;

  /** Parent form. */
  public readonly _parentForm = inject(NgForm, { optional: true }) as NgForm;

  /** Parent from group. */
  public readonly _parentFormGroup = inject(FormGroupDirective, { optional: true }) as FormGroupDirective;

  /** Default error state matcher. */
  public readonly _defaultErrorStateMatcher = inject(ErrorStateMatcher);

  /** Element ref. */
  public readonly _elementRef = inject(ElementRef);
}

const baseMatFormControlMixin = mixinTabIndex(
  mixinColor(mixinDisabled(mixinErrorState(BaseMatFormFieldValueAccessor))),
);

/**
 * Base mat form field control.
 * Used to create a custom control for a mat-form-field.
 * @example
 * ```ts
 * // example-control.component.ts
 * export class ExampleComponent extends BaseMatFormFieldControl<{name: string}> {
 *   public readonly nameControl = new FormControl('');
 *   public ngOnInit() {
 *     nameControl.valueChanges.subscribe(name => this.value = { name })
 *   }
 * }
 * ```
 * ```html
 * <!-- example-control.component.html -->
 * <input formControlName="nameControl">
 * ```
 * Usage:
 * ```html
 * <!-- component-use-example-control.component.html -->
 * <mat-form-field>
 *   <example-control formControl="userNameControl"></example-control>
 * </mat-form-field>
 * ```
 * Angular Material's MatInput and MatSelect components were used as references: \
 * https://github.com/angular/components/blob/main/src/material/input/input.ts \
 * https://github.com/angular/components/blob/main/src/material/select/select.ts
 */
@Directive()
export abstract class MatFormFieldValueAccessor<T> extends baseMatFormControlMixin
  implements DoCheck, OnDestroy, MatFormFieldControl<T> {
  private static nextId = 0;

  /** @inheritdoc */
  @HostBinding()
  public readonly id = `dartsalesc-form-control-${MatFormFieldValueAccessor.nextId++}`;

  /** Described by. */
  @HostBinding('attr.aria-describedBy')
  public describedBy = '';

  private _placeholder = '';

  /** @inheritdoc */
  @Input()
  public set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  /** @inheritdoc */
  public get placeholder(): string {
    return this._placeholder;
  }

  private _required = false;

  /** @inheritdoc */
  @Input()
  public set required(required: boolean | string) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  /** @inheritdoc */
  public get required(): boolean {
    return this._required;
  }

  private _focused = false;

  /** @inheritdoc */
  public set focused(isFocused: boolean) {
    this._focused = isFocused;
  }

  /** @inheritdoc */
  public get focused(): boolean {
    return this._focused;
  }

  private _value: T | null = null;

  /** @inheritdoc */
  public set value(value: T | null) {
    this._value = value;
    this.emitChange(this._value);
  }

  /** @inheritdoc */
  public get value(): T | null {
    return this._value;
  }

  /** @inheritdoc */
  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  /** @inheritdoc */
  public get empty(): boolean {
    return !this.ngControl.value;
  }

  /** Control value (it can be used instead of 'value' for compatibility with `SimpleValueAccessor`). */
  protected set controlValue(val: T | null) {
    this.value = val;
  }

  /** Control value (it can be used instead of 'value' for compatibility with `SimpleValueAccessor`). */
  protected get controlValue(): T | null {
    return this.value;
  }

  public constructor(protected override readonly changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /** @inheritdoc */
  public ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  /** On focus out. */
  @HostListener('focusout')
  public onFocusOut(): void {
    this.emitTouched();
    this.onFocusChanged(false);
    this.onBlur();
  }

  /** On focus. */
  @HostListener('focusin')
  public onFocus(): void {
    this.onFocusChanged(true);
  }

  /**
   * Used by Angular to write when assigned FormControl is changed.
   * Don't use to change values, use controlValue setter.
   * @param value Value passed from the outside of value accessor by Angular's FormControl.
   */
  public override writeValue(value: T | null): void {
    this.controlValue = value;
    this.changeDetectorRef.markForCheck();
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    this.stateChanges.next();
    this.changeDetectorRef.markForCheck();
  }

  /** @inheritdoc */
  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  /** @inheritdoc */
  public onContainerClick(): void {
    if (!this.focused) {
      this.focus();
    }
  }

  /** Focus handler which should be implemented in subclass. */
  protected abstract focus(): void;

  /** On blur. This method can be implemented in subclasses. */
  protected onBlur(): void {
    return undefined;
  }

  private onFocusChanged(isFocused: boolean): void {
    // We need this timeout to prevent change detection errors.
    setTimeout(() => {
      if (isFocused !== this.focused) {
        this.focused = isFocused;
        this.stateChanges.next();
      }
    }, 0);
  }
}
