import { UserRole } from '../enums/user-role';

import { ProjectUserPermission } from './project-user-permission';
import { UserBase } from './user-base';

/** Organization user. */
export class OrganizationUser extends UserBase {
  /** Project permissions (might be 'null' if Project permissions are not included in server response). */
  public readonly projectPermissions: ProjectUserPermission | null;

  /** Created date. */
  public readonly createdAt: Date | null;

  /** User roles on Organization level. Consist of Organization roles + Default Project roles. */
  public readonly organizationRoles: readonly UserRole[];

  public constructor(data: OrganizationUserInitArgs) {
    super(data);
    this.projectPermissions = data.projectPermissions;
    this.createdAt = data.createdAt;
    this.organizationRoles = data.organizationRoles;
  }
}

type OrganizationUserInitArgs = OrganizationUser;
