import { OrganizationApprovalStatus } from '../enums/organization-approval-status';

/** Organization approvals. */
export class OrganizationApproval {
  /** Organization id. */
  public readonly organizationId: number;

  /** Organization name. */
  public readonly organizationName: string;

  /** Organization approval status. */
  public readonly status: OrganizationApprovalStatus;

  public constructor(data: OrganizationApprovalInitArgs) {
    this.organizationId = data.organizationId;
    this.organizationName = data.organizationName;
    this.status = data.status;
  }
}
type OrganizationApprovalInitArgs = OrganizationApproval;
