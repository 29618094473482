import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Loading state directive. Applies loading state on element. Color and size are customizable via `--spinner-color` and `--spinner-size`.
 * @example
 * ```html
 * <button [atsdartcLoading]="isLoading$ | async" (click)="isLoading$.next(true)">Submit</button>
 * ```
 * Be careful! Attribute "disabled" doesn't work with this directive.
 */
@Directive({
  selector: '[atsdartcLoading]',
})
export class LoadingDirective {
  /** Loading beacon. */
  @Input()
  public set atsdartcLoading(loading: boolean | null) {
    let updateState: () => void;
    if (loading) {
      this.elementRef.nativeElement.classList.add('atsdartc-loading');
      updateState = () => this.disable();
    } else {
      this.elementRef.nativeElement.classList.remove('atsdartc-loading');
      updateState = () => this.enable();
    }

    // Timeout is required to ensure state is updated correctly.
    setTimeout(() => {
      updateState();
    }, 0);
  }

  /**
   * @param elementRef Element ref.
   */
  public constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  /** Enable element. */
  private disable(): void {
    const element = this.elementRef.nativeElement;
    element.setAttribute('disabled', 'true');
    if (element.classList.contains('mat-mdc-button-base')) {
      element.classList.add('mat-mdc-button-disabled');
    }
  }

  /** Disable element. */
  private enable(): void {
    const element = this.elementRef.nativeElement;
    element.removeAttribute('disabled');
    element.classList.remove('mat-mdc-button-disabled');
  }
}
