import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { TableColumnInfo } from '@atsdart/common/core/models/column-info';
import { UserFilters, UserFiltersForm } from '@atsdart/common/core/models/filters/user-filters';
import { PaginationData } from '@atsdart/common/core/models/pagination-data';
import { User } from '@atsdart/common/core/models/user';
import { UsersApiService } from '@atsdart/common/core/services/api/users-api.service';
import { DATE_TIME_FORMAT } from '@atsdart/common/core/utils/constants';
import { ListManager, InfiniteScrollListStrategy } from '@atsdart/common/core/utils/list-manager';
import { trackById } from '@atsdart/common/core/utils/trackby';
import { Subject, shareReplay, map, Observable, startWith, BehaviorSubject, EMPTY, filter, combineLatest, switchMap, first } from 'rxjs';
import { OrganizationsApiService } from '@atsdart/common/core/services/api/organizations-api.service';
import { catchAppError } from '@atsdart/common/core/utils/rxjs/catch-app-error';
import { MatDialog } from '@angular/material/dialog';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';
import { UserStatus } from '@atsdart/common/core/enums/user-status';
import { UserProfile } from '@atsdart/common/core/models/user-profile';
import { NotificationService } from '@atsdart/common/core/services/notification.service';
import { ConfirmDialogService } from '@atsdart/common/core/services/confirm-dialog.service';
import { CurrentOrganizationService } from '@atsdart/common/core/services/current-organization.service';

import { UserRoleDialogComponent } from '../../../organizations/pages/organization-edit-page/user-role-dialog/user-role-dialog.component';
import { ConfirmDeleteUserDialogService } from '../../services/confirm-delete-user-dialog.service';

/** Members table. */
@Destroyable()
@Component({
  selector: 'atsdartw-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersTableComponent implements OnInit {

  /** Organization id. */
  @Input()
  public set organizationId(value: number | null) {
    if (value) {
      this.organizationId$.next(value);
    }
  }

  /** Current user. */
  @Input()
  public currentUser: UserProfile | null = null;

  /** Contains organization id. */
  protected readonly organizationId$ = new BehaviorSubject<number | null>(null);

  /** Date format. */
  protected readonly dateFormat = DATE_TIME_FORMAT;

  /** Trigger search. */
  protected readonly startSearch$ = new Subject<void>();

  /** Filters. */
  protected readonly filters$ = this.initFiltersStream();

  /** List manager. */
  protected readonly listManager = new ListManager<User, UserFilters>({
    strategy: new InfiniteScrollListStrategy(),
    filter$: this.filters$,
    pagination: new PaginationData({
      pageSize: 20,
    }),
  });

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly userService = inject(UsersApiService);

  private readonly organizationsApiService = inject(OrganizationsApiService);

  private readonly dialogService = inject(MatDialog);

  private readonly notificationService = inject(NotificationService);

  private readonly confirmDialogService = inject(ConfirmDialogService);

  private readonly currentOrganizationService = inject(CurrentOrganizationService);

  private readonly confirmDeleteUserDialogService = inject(ConfirmDeleteUserDialogService);

  /** Members list. */
  protected readonly members$ = this.createMembersStream();

  /** Filters form. */
  protected readonly filtersForm = this.createFiltersForm();

  /** Table columns. */
  protected readonly tableColumns = this.createTableColumns();

  /** Server error message. */
  protected readonly errorMessage$ = new BehaviorSubject<string | null>(null);

  /** Track by id. */
  protected readonly trackById = trackById;

  /** @inheritDoc */
  public ngOnInit(): void {
    this.subscribeToFiltersChange();
  }

  /**
   *  Handles click on add member button.
   *  @param user User.
   *  @param organizationId Organization Id.
   */
  protected onClickAddMember(user: User, organizationId: number): void {
    this.dialogService
      .open(UserRoleDialogComponent, {
        data: {
          user,
          organizationId,
          isEditRoles: false,
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
      )
      .subscribe(() => this.listManager.returnToFirstPage());
  }

  /**
   *  Handles click on delete member button.
   *  @param id User id.
   *  @param organizationId Organization id.
   */
  protected onClickDeleteMember(id: number, organizationId: number): void {
    this.errorMessage$.next(null);
    this.confirmDeleteUserDialogService.openDialog().pipe(
      first(),
      filter(Boolean),
      switchMap(() => this.deleteUser(id, organizationId)),
    )
      .subscribe(message => {
        this.notificationService.showSuccess(message);
        this.listManager.returnToFirstPage();
      });
  }

  /**
   * Handles click on edit roles button.
   * @param user User.
   * @param organizationId Organization Id.
   */
  protected onClickEditRoles(user: User, organizationId: number): void {
    this.dialogService
      .open(UserRoleDialogComponent, {
        data: {
          user,
          organizationId,
          isEditRoles: true,
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
      )
      .subscribe(() => this.listManager.returnToFirstPage());

  }

  /**
   * Get organization roles as a list.
   * @param user User.
   */
  protected getOrganizationRoles(user: User): string {
    return user.userOrganizationPermissions?.roles
      .filter(role => UserRole.isOrganizationRole(role))
      .map(role => UserRole.toReadable(role))
      .join(', ') ?? '';
  }

  /**
   * Get project roles as a list.
   * @param user User.
   */
  protected getProjectRoles(user: User): string {
    return user.userOrganizationPermissions?.roles
      .filter(role => UserRole.isProjectRole(role))
      .map(role => UserRole.toReadable(role))
      .join(', ') ?? '';
  }

  private subscribeToFiltersChange(): void {
    this.initFiltersStream().pipe(
      takeUntilDestroy(this),
    )
      .subscribe(filters => {
        this.listManager.filtersChanged(filters);
      });
  }

  private initFiltersStream(): Observable<UserFilters> {
    return combineLatest([this.organizationId$, this.startSearch$.pipe(startWith(null))]).pipe(
      map(([organizationId]) => ({
        organizationIdToGetPermissions: organizationId ?? 0,
        ...this.filtersForm.value,
        userStatus: UserStatus.Active,
      })),
    );
  }

  private createMembersStream(): Observable<User[]> {
    return this.listManager.getPaginatedItems(options => this.userService.getUsersList(options)).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createTableColumns(): TableColumnInfo[] {
    const defaultTableColumns: TableColumnInfo[] = [
      { name: 'firstName', headerText: 'First Name', sort: 'firstName' },
      { name: 'lastName', headerText: 'Last Name', sort: 'lastName' },
      { name: 'isMember', headerText: 'Member' },
      { name: 'organizationRoles', headerText: 'Organization Role(s)' },
      { name: 'projectRoles', headerText: 'Default Project Role(s)' },
      { name: 'email', headerText: 'Email', sort: 'email' },
      { name: 'createdAt', headerText: 'Date Created', sort: 'createdAt' },
      { name: 'operation', headerText: '' },
    ];

    return defaultTableColumns;
  }

  private createFiltersForm(): FormGroup<UserFiltersForm> {
    return this.fb.group<UserFiltersForm>({
      firstOrLastName: this.fb.control(undefined),
      email: this.fb.control(undefined),
    });
  }

  private deleteUser(userId: number, organizationId: number): Observable<string> {
    return this.organizationsApiService.deleteUser(organizationId, userId).pipe(
      first(),
      catchAppError(error => {
        this.errorMessage$.next(error.message);
        return EMPTY;
      }),
    );
  }
}
