import { Directive, Input, ContentChild } from '@angular/core';

import { TableCellDirective } from './table-cell.directive';
import { TableHeaderDirective } from './table-header.directive';

/**
 * Directive to specify how to render column in the table.
 * @example
 * ```html
 * <ng-container atsdartcTableColumn="name">
 *   <ng-container *atsdartcTableHeader>
 *     Name
 *   </ng-container>
 *   <ng-container [atsdartcTableCell]="nameCell">
 *     <ng-template #nameCell let-row>
 *       {{ row.name }}
 *     </ng-template>
 *   </ng-container>
 * </ng-container>
 *
 * <ng-container
 *   atsdartcTableColumn="name"
 *   headerText="Name">
 *   <ng-container *atsdartcTableCell="let row">
 *     {{row.name}}
 *   </ng-container>
 * </ng-container>
 * ```
 */
@Directive({
  selector: '[atsdartcTableColumn]',
})
export class TableColumnDirective<T = unknown> {
  /** Name of the column. */
  @Input('atsdartcTableColumn')
  public name!: string;

  /**
   * Function to get class for cells in this column.
   * Note: You can access passed class only in global CSS scope (or by turning off encapsulation in parent component).
   */
  @Input()
  public cellClassesGetter?: (row: T, cellName: string) => string | string[];

  /** Text for the header if TableHeaderDirective not provided. */
  @Input()
  public headerText?: string;

  /** Max column width in pixels. */
  @Input()
  public maxWidth?: number;

  /** Get cell template. */
  @ContentChild(TableCellDirective)
  public cell?: TableCellDirective;

  /** Get header template. */
  @ContentChild(TableHeaderDirective)
  public header?: TableHeaderDirective;
}
