
/**
 * Sets date without user timezone offset.
 * @param date Date.
 */
export function setDateWithoutTimezoneOffset(date: Date): Date {

  // Gets timezone offset in milliseconds.
  const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() - userTimezoneOffset);
}

/**
 * Gets date without user timezone offset.
 * @param date Date.
 */
export function getDateWithoutTimezoneOffset(date: Date): Date {

  // Gets timezone offset in milliseconds.
  const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() + userTimezoneOffset);
}

/**
 * Whether Date is valid or not.
 * @param date Date.
 */
export function isInvalidDate(date?: Date | null): boolean {
  return isNaN(Number(date));
}
