import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { UserRolesForm } from '@atsdart/common/core/models/forms/user-roles-form';
import { User } from '@atsdart/common/core/models/user';
import { OrganizationsApiService } from '@atsdart/common/core/services/api/organizations-api.service';
import { NotificationService } from '@atsdart/common/core/services/notification.service';
import { catchAppError } from '@atsdart/common/core/utils/rxjs/catch-app-error';
import { toggleExecutionState } from '@atsdart/common/core/utils/rxjs/toggle-execution-state';
import { ToggleSubject } from '@atsdart/common/core/utils/rxjs/toggle-subject';
import { EMPTY, Observable } from 'rxjs';

/** Dialog data. */
interface UserRolesDialogData {

  /** User info. */
  readonly user: User;

  /** Organization id. */
  readonly organizationId: number;

  /** Flag is edit user roles in organization. */
  readonly isEditRoles: boolean;

}

/** Dialog for adding a user role in the organization. */
@Component({
  selector: 'atsdartw-user-role-dialog',
  templateUrl: './user-role-dialog.component.html',
  styleUrls: ['./user-role-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleDialogComponent implements OnInit {

  /** Namespace user role. */
  protected readonly userRole = UserRole;

  /** Loading. */
  protected readonly isLoading$ = new ToggleSubject();

  /** Form group. */
  protected readonly form = this.fb.group<UserRolesForm>({
    organizationRoles: this.fb.control([]),
    projectRole: this.fb.control(null),
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: UserRolesDialogData,
    private readonly dialogRef: MatDialogRef<UserRoleDialogComponent>,
    private readonly organizationsApiService: OrganizationsApiService,
    private readonly fb: NonNullableFormBuilder,
    private readonly notificationService: NotificationService,
  ) { }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.fillForm();
  }

  /** Handles submit form. */
  protected onSubmitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const projectRole = this.form.controls.projectRole.value;
    const organizationRole = this.form.controls.organizationRoles.value;
    const userRoles = projectRole ?
      organizationRole.concat(projectRole) :
      organizationRole;

    const request$ = this.data.isEditRoles ?
      this.updateUserRoles(userRoles) :
      this.addUserToOrganization(userRoles);

    request$.pipe(
      toggleExecutionState(this.isLoading$),
      catchAppError(error => {
        this.dialogRef.close(true);
        this.notificationService.showError(error.message);
        return EMPTY;
      }),
    )
      .subscribe(message => {
        this.notificationService.showSuccess(message);
        this.dialogRef.close(true);
      });
  }

  private fillForm(): void {
    this.form.patchValue({
      organizationRoles: this.data.user.userOrganizationPermissions?.roles.filter(role => UserRole.isOrganizationRole(role)) ?? [],
      projectRole: this.data.user.userOrganizationPermissions?.roles.filter(role => UserRole.isProjectRole(role)).at(0) ?? null,
    });
  }

  private updateUserRoles(userRoles: UserRole[]): Observable<string> {
    return this.organizationsApiService.updateUserRolesInOrganization(
      this.data.organizationId ?? 0,
      this.data.user.id,
      userRoles,
    );
  }

  private addUserToOrganization(userRoles: UserRole[]): Observable<string> {
    return this.organizationsApiService.addUserToOrganization(
      this.data.organizationId ?? 0,
      this.data.user,
      userRoles,
    );
  }
}
