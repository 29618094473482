import { routePaths } from '@atsdart/common/core/utils/route-paths';

import { NavItem } from '../nav-list-item/navigation-item';

// User permissions: https://saritasa.atlassian.net/wiki/spaces/ATADEV/pages/2804842499/Admin+Panel+user+permissions

/** Nav items for all user. */
export const sharedRoutes: NavItem[] = [
  {
    label: 'Authority Management',
    iconName: 'user-light',
    path: routePaths.authorityManagement[0],
    expandedByDefault: true,
    children: [
      {
        label: 'Users',
        path: routePaths.usersManagement[0],
        hasImplicitChildren: true,
      },
      {
        label: 'Design Projects Access and Roles',
        path: routePaths.projectAccessManagement[0],
        hasImplicitChildren: true,
      },
      {
        label: 'S&E Projects',
        path: routePaths.projects[0],
        hasImplicitChildren: true,
      },
    ],
  },
  {
    label: 'Resources',
    iconName: 'components-light',
    path: routePaths.resources[0],
    children: [
      {
        label: 'Port Types',
        path: routePaths.ports[0],
        hasImplicitChildren: true,
      },
    ],
  },
  {
    label: 'Template Admin',
    iconName: 'templates-light',
    path: routePaths.templateAdmin[0],
  },
];

/** Nav items for Global Admin. */
export const navItemsForGlobalAdmin: NavItem[] = [
  sharedRoutes[0],
  {
    label: 'Organizations',
    iconName: 'organization-light',
    path: routePaths.organizations[0],
    hasImplicitChildren: true,
  },
  {
    ...sharedRoutes[1],
    children: [
      {
        label: 'Port Types',
        path: routePaths.ports[0],
        hasImplicitChildren: true,
      },
      {
        label: 'Components',
        path: routePaths.components[0],
        hasImplicitChildren: true,
      },
      {
        label: 'Diagrams',
        path: routePaths.diagrams[0],
        hasImplicitChildren: true,
      },
    ],
  },
  {
    ...sharedRoutes[2],
    children: [
      {
        label: 'Tutorial Videos',
        path: routePaths.tutorialVideos[0],
        hasImplicitChildren: true,
      },
      {
        label: 'Manage Templates',
        path: routePaths.templatesManagement[0],
        hasImplicitChildren: true,
      },
    ],
  },
];

/** Nav items for users who manage an organization (users with OrganizationAdmin role). */
export const navItemsForOrganizationAdmin: NavItem[] = [
  sharedRoutes[0],
  {
    label: 'Org Account Settings',
    iconName: 'organization-light',
    path: routePaths.organization[0],
    hasImplicitChildren: true,
  },
  sharedRoutes[1],
  {
    ...sharedRoutes[2],
    children: [
      {
        label: 'Manage Templates',
        path: routePaths.templatesManagement[0],
        hasImplicitChildren: true,
      },
    ],
  },
];

/** Nav items for users who manage users (users with UserAdmin role). */
export const navItemsForUserAdmin: NavItem[] = [
  sharedRoutes[0],
  {
    label: 'Org Account Settings',
    iconName: 'organization-light',
    path: routePaths.organization[0],
    hasImplicitChildren: true,
  },
];

/** Nav items for users who not manage an organization (users who do not have OrganizationAdmin or UserAdmin roles). */
export const navItemsForUsersNotManageOrganization: NavItem[] = [
  {
    ...sharedRoutes[1],
    children: [
      {
        label: 'Port Types',
        path: routePaths.ports[0],
        hasImplicitChildren: true,
      },
      {
        label: 'Components',
        path: routePaths.components[0],
        hasImplicitChildren: true,
      },
      {
        label: 'Diagrams',
        path: routePaths.diagrams[0],
        hasImplicitChildren: true,
      },
    ],
  },
  {
    ...sharedRoutes[2],
    children: [
      {
        label: 'Manage Templates',
        path: routePaths.templatesManagement[0],
        hasImplicitChildren: true,
      },
    ],
  },
];
