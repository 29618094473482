<div *ngLet="listManager.listLoading$ | async as isLoading">
  <atsdartw-members-table-filters
    [filtersForm]="filtersForm"
    [isLoading]="isLoading"
    (submitForm)="startSearch$.next()"
  >
  </atsdartw-members-table-filters>
  <mat-error *ngIf="errorMessage$ | async as error" class="error">
    {{ error }}
  </mat-error>
</div>

<mat-divider class="divider" />
<ng-container *ngIf="currentUser">
  <atsdartc-base-table
    *ngLet="members$ | async as members"
    class="members-table"
    [loading]="listManager.listLoading$ | async"
    [rows]="members$ | async"
    [columns]="tableColumns"
    [trackBy]="trackById"
    [pagination]="listManager.pagePagination$ | async"
    [sort]="listManager.sort$ | async"
    [isStickyLastColumn]="true"
    [isLastPage]="listManager.isLastPage"
    (sortChange)="listManager.sortChanged($event)"
    (paginationChange)="listManager.paginationChanged($event, true)"
  >
    <!-- Created at column. -->
    <ng-container atsdartcTableColumn="createdAt" [maxWidth]="120">
      <ng-container [atsdartcTableCell]="createdAt">
        <ng-template #createdAt let-row>
          {{ row.createdAt | date: dateFormat }}
        </ng-template>
      </ng-container>
    </ng-container>

    <!-- Email column. -->
    <ng-container atsdartcTableColumn="email" [maxWidth]="220" />
    <!-- Member column. -->
    <ng-container atsdartcTableColumn="isMember" [maxWidth]="40">
      <ng-container [atsdartcTableCell]="member">
        <ng-template #member let-row>
          <div class="member-icon">
            <mat-icon>
              {{ row.userOrganizationPermissions ? "check_circle" : "" }}
            </mat-icon>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
    <!-- First name column. -->
    <ng-container atsdartcTableColumn="firstName" [maxWidth]="120" />
    <!-- Last name column. -->
    <ng-container atsdartcTableColumn="lastName" [maxWidth]="120" />
    <!-- Organization Role(s) column. -->
    <ng-container atsdartcTableColumn="organizationRoles" [maxWidth]="120">
      <ng-container [atsdartcTableCell]="organizationRoles">
        <ng-template #organizationRoles let-row>
          {{ getOrganizationRoles(row) }}
        </ng-template>
      </ng-container>
    </ng-container>

    <!-- Default Project Role(s) column. -->
    <ng-container atsdartcTableColumn="projectRoles" [maxWidth]="120">
      <ng-container [atsdartcTableCell]="projectRoles">
        <ng-template #projectRoles let-row>
          {{ getProjectRoles(row) }}
        </ng-template>
      </ng-container>
    </ng-container>

    <!-- Operations at column. -->
    <ng-container
      *ngLet="organizationId$ | async as organizationId"
      atsdartcTableColumn="operation"
      [maxWidth]="180"
    >
      <ng-container [atsdartcTableCell]="operation">
        <ng-template #operation let-row>
          <ng-container *ngIf="!row.isGlobalAdmin && row.id !== currentUser.id">
            <div class="operation-buttons">
              <button
                mat-icon-button
                class="operation-icon-button--small"
                type="button"
                [disabled]="row.userOrganizationPermissions"
                (click)="onClickAddMember(row, organizationId)"
              >
                <mat-icon>add</mat-icon>
              </button>
              <button
                mat-icon-button
                class="operation-icon-button--small"
                type="button"
                [disabled]="!row.userOrganizationPermissions"
                (click)="onClickDeleteMember(row.id, organizationId)"
              >
                <mat-icon>remove</mat-icon>
              </button>
              <button
                mat-stroked-button
                class="operation-button--large"
                type="button"
                [disabled]="!row.userOrganizationPermissions"
                (click)="onClickEditRoles(row, organizationId)"
              >
                Edit Roles
              </button>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </atsdartc-base-table>
</ng-container>
