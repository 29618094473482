import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconsService } from '@atsdart/common/core/services/icons.service';

/** Root component. */
@Component({
  selector: 'atsdartw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public constructor(iconsService: IconsService) {
    iconsService.registerIcons();
  }
}
