import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { CurrentOrganizationService } from '@atsdart/common/core/services/current-organization.service';
import { UserAuthService } from '@atsdart/common/core/services/user-auth.service';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { ConditionalRenderingDirective } from './conditional-rendering.directive';

/**
 * Directive which allows to show element only for specific user role (except Global Admin).
 * @example
 * ```html
 * <div *atsdartcRestrictUserRoles="[UserRole.Engineer]">
 *   <!-- Content. -->
 * </div>
 * ```
 */
@Directive({
  selector: '[atsdartcRestrictUserRoles]',
})
@Destroyable()
export class RestrictUserTypesDirective extends ConditionalRenderingDirective implements OnInit {
  /** Roles. */
  @Input('atsdartcRestrictUserRoles')
  public set roles(roles: UserRole[]) {
    this.allowedRoles$.next(roles);
  }

  private readonly allowedRoles$ = new BehaviorSubject<UserRole[]>([]);

  public constructor(
    private readonly currentUserService: UserAuthService,
    private readonly currentOrganizationService: CurrentOrganizationService,
    protected override readonly viewContainer: ViewContainerRef,
    protected override readonly changeDetector: ChangeDetectorRef,
    protected override readonly templateRef: TemplateRef<unknown>,
  ) {
    super(viewContainer, changeDetector, templateRef);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.checkUserRolesAndRenderContent();
  }

  private checkUserRolesAndRenderContent(): void {
    combineLatest([
      this.currentUserService.currentUser$,
      this.currentOrganizationService.organization$,
      this.allowedRoles$,
    ]).pipe(
      takeUntilDestroy(this),
    )
      .subscribe(([user, organization, allowedRoles]) => {
        const userRoles = organization?.roles ?? [];
        if (Boolean(user?.isGlobalAdmin) || userRoles.some(role => allowedRoles.includes(role))) {
          this.renderContent();
        } else {
          this.clearContent();
        }
      });
  }
}
