import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatFormFieldValueAccessor, matFormFieldControlProviderFor } from '@atsdart/common/core/utils/mat-form-field-value-accessor';

/** Uploading file. */
@Component({
  selector: 'atsdartw-uploading-file',
  templateUrl: './uploading-file.component.html',
  styleUrls: ['./uploading-file.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => UploadingFileComponent)],
})
export class UploadingFileComponent extends MatFormFieldValueAccessor<File | null> {

  /** File types to allow. */
  @Input()
  public allowedFileTypes: readonly string[] = [];

  /** Input label. */
  @ViewChild(MatInput)
  private readonly matInput?: MatInput;

  /** @inheritdoc */
  protected override focus(): void {
    this.matInput?.focus();
  }

  /**
   * Handle change upload file.
   * @param event File changed.
   */
  protected onFileChange(event: Event): void {
    if (event.target != null) {
      const file = (event.target as HTMLInputElement).files?.item(0);
      if (file != null) {
        this.controlValue = file;
      }
    }
  }
}
