/**
 * We should pass environment variables during app build process.
 * Now we have the following configuration for different environments:
 * Development - use 'env.development' file for env variables;
 * Staging - env variables are provided in CI;
 * Production - env variables are provided in CI.
 */
export const environment = {
  production: process.env.NG_APP_ENV === 'production' || process.env.NG_APP_ENV === 'staging',
  apiUrl: process.env.NG_APP_API_URL,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
