import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
import { LoadingDirective } from './directives/loading.directive';
import { FormControlValidationMessageComponent } from './components/form-control-validation-message/form-control-validation-message.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component';
import { BaseTableComponent } from './components/base-table/base-table.component';
import { NgLet } from './directives/ng-let';
import { TableCellDirective, TableColumnDirective, TableHeaderDirective } from './directives/table';
import { RestrictUserTypesDirective } from './directives/restrict-user-roles.directive';
import { ChipsListWithSuggestedComponent } from './components/chips-list-with-suggested/chips-list-with-suggested.component';
import { AutocompleteFixedScrollDirective } from './directives/autocomplete-fixed-scroll.directive';

const PRIVATE_DECLARATIONS: Type<unknown>[] = [ValidationMessageComponent];

const EXPORTED_DECLARATIONS: Type<unknown>[] = [
  // Components.
  FormControlValidationMessageComponent,
  SidePanelComponent,
  BaseDialogComponent,
  BaseTableComponent,
  ValidationMessageComponent,
  ChipsListWithSuggestedComponent,

  // Directives.
  LoadingDirective,
  NgLet,
  TableCellDirective,
  TableColumnDirective,
  TableHeaderDirective,
  RestrictUserTypesDirective,
  AutocompleteFixedScrollDirective,
];
const EXPORTED_MODULES: Type<unknown>[] = [MaterialModule, ReactiveFormsModule];

/** Common module. Contains entities shared across applications. */
@NgModule({
  declarations: [...PRIVATE_DECLARATIONS, ...EXPORTED_DECLARATIONS],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    ...EXPORTED_MODULES,
  ],
  exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES],
})
export class CommonSharedModule {}
