import { enumToArray } from '../utils/enum-to-array';

import { ProjectUserRole } from './project-user-role';

/** User Roles on Organization level. */
export enum UserRole {

  /** Organization Admin. */
  OrganizationAdmin = 'OrganizationAdmin',

  /** UserAdmin. */
  UserAdmin = 'UserAdmin',

  /** Manager. */
  Manager = 'Manager',

  /** Developer. */
  Developer = 'Developer',

  /** Sales manager. */
  SalesManager = 'SalesManager',

  /** Sales person. */
  SalesPerson = 'SalesPerson',

  /** ProjectEngineer. */
  ProjectEngineer = 'ProjectEngineer',

  /** ProjectTechnician. */
  ProjectTechnician = 'ProjectTechnician',

  /** ProjectCxEngineer. */
  ProjectCxEngineer = 'ProjectCxEngineer',
}

export namespace UserRole {
  const TITLE_MAP: Record<UserRole, string> = {
    [UserRole.OrganizationAdmin]: 'Organization Admin',
    [UserRole.UserAdmin]: 'User Admin',
    [UserRole.Manager]: 'Manager',
    [UserRole.Developer]: 'Developer',
    [UserRole.SalesManager]: 'Sales Manager',
    [UserRole.SalesPerson]: 'Salesperson',
    [UserRole.ProjectEngineer]: 'Project Engineer',
    [UserRole.ProjectTechnician]: 'Project Technician',
    [UserRole.ProjectCxEngineer]: 'Project Cx Engineer',
  };

  const DESCRIPTION_MAP: Record<UserRole, string> = {
    [UserRole.OrganizationAdmin]:
      `Highest role with the most permissions. Gives users permission to do anything within the Organization and
      write access to all projects.Full access to the Admin Panel is granted.`,
    [UserRole.UserAdmin]: `Access to users and user permissions within their Organization.
    They can edit user permissions, add new users, remove users, assign users to projects, and
    remove users from projects. Partial access to the Admin Panel is granted.`,
    [UserRole.Manager]: `Write access to all projects within the Organization.
    Having this role will override any selected Default Project Roles.
    These users do not have to be individually added to projects.`,
    [UserRole.Developer]: `Write access to Org managed things. I.e.,
    Component Catalog, Diagram Catalog, Project Templates, etc... Partial access to the Admin Panel is granted.`,
    [UserRole.SalesManager]: `Write access to all sales projects, sales resources, sales project templates,
    services catalog, and points list catalog within the Organization.`,
    [UserRole.SalesPerson]: `Write access only to sales projects that the user has been added to
    (“Other Team Members” field). Can create new projects only from sales project templates.`,
    [UserRole.ProjectEngineer]: 'Write access to projects they are assigned to.',
    [UserRole.ProjectTechnician]: 'Limited access to projects that they are assigned to.',
    [UserRole.ProjectCxEngineer]: `Very limited access to projects that they are assigned to. Can access Diagrams, Pages, Drawing Packages.
    No access to Files, Schedules, RFIs. Can Save and Push projects`,
  };

  const PROJECT_ROLE_MAP: Partial<Record<UserRole, ProjectUserRole>> = {
    [UserRole.ProjectEngineer]: ProjectUserRole.Engineer,
    [UserRole.ProjectTechnician]: ProjectUserRole.Technician,
    [UserRole.ProjectCxEngineer]: ProjectUserRole.CxEngineer,
  };

  /** User Role wight. Bigger values means role has more permissions. */
  const USER_ROLE_WEIGHT_MAP: Record<UserRole, number> = {
    [UserRole.OrganizationAdmin]: 3,
    [UserRole.UserAdmin]: 2,
    [UserRole.Manager]: 1,
    [UserRole.Developer]: 1,
    [UserRole.SalesManager]: 1,
    [UserRole.SalesPerson]: 1,
    [UserRole.ProjectEngineer]: 1,
    [UserRole.ProjectTechnician]: 1,
    [UserRole.ProjectCxEngineer]: 1,
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  export function toReadable(value: UserRole): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /**
   * Convert a certain enum value to readable title for project roles.
   * @param value Value of enum.
   */
  export function toProjectRole(value: UserRole): ProjectUserRole | '' {
    const readable = PROJECT_ROLE_MAP[value];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /** Get list of all enum items. */
  export function toArray(): UserRole[] {
    return enumToArray(UserRole);
  }

  /** Get list of project roles. */
  export function toProjectRolesArray(): UserRole[] {
    return [
      UserRole.ProjectEngineer,
      UserRole.ProjectTechnician,
      UserRole.ProjectCxEngineer,
    ];
  }

  /** Get list of organization roles. */
  export function toOrganizationRolesArray(): UserRole[] {
    return [
      UserRole.Developer,
      UserRole.Manager,
      UserRole.SalesManager,
      UserRole.SalesPerson,
      UserRole.OrganizationAdmin,
      UserRole.UserAdmin,
    ];
  }

  /**
   * Get role description.
   * @param role User role.
   */
  export function getRoleDescription(role: UserRole): string {
    const readable = DESCRIPTION_MAP[role];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /**
   * Whether the role is organizational.
   * @param role User role.
   */
  export function isOrganizationRole(role: UserRole): boolean {
    return UserRole.toOrganizationRolesArray().includes(role);
  }

  /**
   * Whether the role is project.
   * @param role User role.
   */
  export function isProjectRole(role: UserRole): boolean {
    return UserRole.toProjectRolesArray().includes(role);
  }

  /**
   * Compare whether first user role is higher then the second.
   * @param first First role.
   * @param second Second role.
   */
  export function isRoleHigher(first: UserRole, second: UserRole): boolean {
    return USER_ROLE_WEIGHT_MAP[first] > USER_ROLE_WEIGHT_MAP[second];
  }

  /**
   * Get highest user role from passed array.
   * @param roles Roles array (should not be empty).
   */
  export function getHighestUserRole(roles: readonly UserRole[]): UserRole {
    if (roles.length === 0) {
      return UserRole.Developer;
    }

    return roles.reduce((highestRole, role) => {
      if (isRoleHigher(highestRole, role)) {
        return highestRole;
      }
      return role;
    }, roles[0]);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: UserRole): UserRole {
    return item;
  }
}
