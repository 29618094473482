import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { FetchListOptions } from '../../models/fetch-list-options';
import { UserFilters } from '../../models/filters/user-filters';
import { PagedList } from '../../models/paged-list';
import { EditUser, User } from '../../models/user';
import { UserProfile } from '../../models/user-profile';
import { AppUrlsConfig } from '../app-urls.config';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { UserProfileDto } from '../mappers/dto/user-profile.dto';
import { UserDto } from '../mappers/dto/user.dto';
import { UserFiltersMapper } from '../mappers/filter-mappers/user-filters.mapper';
import { HttpParamsMapper } from '../mappers/http-params.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { UserProfileMapper } from '../mappers/user-profile.mapper';
import { UserMapper } from '../mappers/user.mapper';

/** Users API service. */
@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly http: HttpClient,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly listMapper: PagedListMapper,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly userMapper: UserMapper,
    private readonly userProfileMapper: UserProfileMapper,
    private readonly userFilterMapper: UserFiltersMapper,
  ) { }

  /**
   * Get list of users.
   * @param options Request options.
   */
  public getUsersList(options: FetchListOptions<UserFilters>): Observable<PagedList<User>> {
    const params = this.paramsMapper.toDto(options, this.userFilterMapper);
    return this.http.get<PagedListDto<UserDto>>(this.apiUrls.usersApi.getList, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.userMapper,
        options.pagination,
      )),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Delete user.
   * @param id User id.
   * @returns Success message for the user.
   */
  public deleteUser(id: number): Observable<string> {
    return this.http.delete<void>(this.apiUrls.usersApi.getById(id)).pipe(
      map(() => 'User has been deleted'),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Register user with invite.
   * @param value Form value.
   */
  public inviteRegistration(value: EditUser): Observable<void> {
    const dto = this.userMapper.toCreateDto(value);
    return this.http
      .post<void>(this.apiUrls.register.registerInvite, dto)
      .pipe(
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.userMapper,
        ),
      );
  }

  /**
   * Update user.
   * @param value User.
   * @param id User id.
   * @returns Success message for the user.
   */
  public updateUser(value: EditUser, id: number): Observable<string> {
    const dto = this.userMapper.toDto(value);
    return this.http
      .put<string>(this.apiUrls.usersApi.getById(id), dto)
      .pipe(
        map(() => `${value.firstName} ${value.lastName} user updated.`),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.userMapper,
        ),
      );
  }

  /**
   * Get user by id.
   * @param id User id.
   */
  public getUserById(id: number): Observable<UserProfile> {
    return this.http
      .get<UserProfileDto>(this.apiUrls.usersApi.getById(id))
      .pipe(
        map(userDto => this.userProfileMapper.fromDto(userDto)),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Resend user invitation.
   * @param email User email.
   */
  public resendInvitation(email: string): Observable<string> {
    return this.http
      .post<void>(this.apiUrls.register.registerResendInvitation, { email })
      .pipe(
        map(() => 'Invitation is sent.'),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Resend email confirm.
   * @param email User email.
   */
  public resendEmailConfirm(email: string): Observable<string> {
    return this.http
      .post<void>(this.apiUrls.register.registerResendConfirm, { email })
      .pipe(
        map(() => 'Invitation is sent.'),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Restore user.
   * @param id User id.
   */
  public restoreUser(id: number): Observable<string> {
    return this.http
      .put<string>(this.apiUrls.usersApi.restoreUserById(id), { id })
      .pipe(
        map(() => `User restored.`),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }
}
