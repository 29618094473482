/**
 * Base model for user.
 * Note: this model should not be used directly.
 * It's intended the this model will be extended.
 */
export abstract class UserBase {

  /** Id. */
  public readonly id: number;

  /** Email. */
  public readonly email: string;

  /** First name. */
  public readonly firstName: string;

  /** Last name. */
  public readonly lastName: string;

  public constructor(data: UserBaseInitArgs) {
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}

type UserBaseInitArgs = UserBase;
