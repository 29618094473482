<ng-container *ngLet="isExpanded$ | async as expanded">
  <a
    *ngIf="item"
    mat-list-item
    class="list-item"
    [class.active]="isItemActive(item)"
    [class.has-children]="item.children?.length"
    [class.expanded]="expanded"
    [class.top-level]="depth === 0"
    (click)="onItemSelected(item, expanded)"
  >
    <div
      matListItemIcon
      class="list-item__offset"
      [class.no-icon]="!item.iconName"
      [ngStyle]="{ 'margin-left': depth * 12 + 'px' }"
    ></div>
    <mat-icon
      *ngIf="item.iconName"
      matListItemIcon
      class="list-item__icon"
      [class.icon-with-offset]="depth > 0"
      [svgIcon]="item.iconName"
    >
    </mat-icon>
    <span class="list-item__label">
      {{ item.label }}
    </span>
    <span *ngIf="!collapsed && item?.children?.length" class="list-item__arrow">
      <mat-icon>expand_more</mat-icon>
    </span>
  </a>

  <div class="list-item__children" [class.expanded]="expanded && !collapsed">
    <atsdartw-nav-list-item
      *ngFor="let child of item?.children; trackBy: trackByIndex"
      [item]="child"
      [depth]="depth + 1"
    >
    </atsdartw-nav-list-item>
  </div>
</ng-container>
