import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FetchListOptions } from '../../models/fetch-list-options';
import { buildHttpParams } from '../../utils/build-http-params';

import { FilterMapper } from './filter-mappers/filters.mapper';
import { IMapperToDto } from './mappers';
import { PaginationDataMapper } from './pagination-data.mapper';
import { SortMapper } from './sort.mapper';

/**
 * Mapper for HttpParams.
 */
@Injectable({ providedIn: 'root' })
export class HttpParamsMapper<T = {}> implements IMapperToDto<HttpParams, FetchListOptions<T>> {
  public constructor(
    private readonly paginationMapper: PaginationDataMapper,
    private readonly sortMapper: SortMapper,
    private readonly filterMapper: FilterMapper<T>,
  ) { }

  /** @inheritDoc */
  public toDto<TDto>(options: FetchListOptions<T>, filterMapper?: IMapperToDto<TDto, T | undefined>): HttpParams {
    const filters = filterMapper ?
      filterMapper.toDto(options.filter) :
      this.filterMapper.toDto(options.filter);

    return buildHttpParams({
      ...this.paginationMapper.toDto(options.pagination),
      ...this.sortMapper.toDto(options.sort),
      ...filters,
    });
  }
}
