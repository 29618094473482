import { Injectable } from '@angular/core';

import { OrganizationUserProfilePermission } from '../../models/organization-user-permission';

import { OrganizationUserProfilePermissionsDto } from './dto/organization-user-permissions.dto';
import { IMapperFromDto } from './mappers';
import { OrganizationUserRolesMapper } from './organization-user-roles.mapper';

/** Mapper for Organization User profile permissions. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationUserProfilePermissionsMapper implements
  IMapperFromDto<OrganizationUserProfilePermissionsDto, OrganizationUserProfilePermission> {
  public constructor(
    private readonly organizationUserRoleMapper: OrganizationUserRolesMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: OrganizationUserProfilePermissionsDto): OrganizationUserProfilePermission {
    return new OrganizationUserProfilePermission({
      id: dto.id,
      name: dto.name,
      roles: this.organizationUserRoleMapper.fromDto(dto),
    });
  }
}
