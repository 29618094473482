import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { first, map } from 'rxjs';
import { UserAuthService } from '@atsdart/common/core/services/user-auth.service';
import { routePaths } from '@atsdart/common/core/utils/route-paths';
import { UserProfile } from '@atsdart/common/core/models/user-profile';
import { CurrentOrganizationService } from '@atsdart/common/core/services/current-organization.service';
import { trackById } from '@atsdart/common/core/utils/trackby';

/** App toolbar. */
@Component({
  selector: 'atsdartw-app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppToolbarComponent {
  /** Current user. */
  @Input()
  public user: UserProfile | null = null;

  /** Emit event for change sidenav (is opened or not). */
  @Output()
  public readonly sidebarChanged = new EventEmitter<void>();

  /** Organizations. */
  protected readonly organizations$ = this.currentOrganizationService.allowedOrganizationsList$;

  /** Selected organization. */
  protected readonly selectedOrganizationId$ = this.currentOrganizationService.organization$.pipe(
    map(org => org?.id),
  );

  /** Title for Global Admin. */
  protected readonly adminTitle = 'Div25 Super User';

  /** Track by id. */
  protected readonly trackById = trackById;

  public constructor(
    private readonly userService: UserAuthService,
    private readonly router: Router,
    private readonly currentOrganizationService: CurrentOrganizationService,
  ) { }

  /**
   * Change selected organization.
   * @param id Organization id.
   */
  protected changeSelectedOrganization(id: number): void {
    this.currentOrganizationService.setSelectedOrganization(id);
  }

  /**
   * Toggle sidenav state.
   */
  protected onToggleSidebarOpened(): void {
    this.sidebarChanged.emit();
  }

  /** Handle click on logout button. */
  protected onLogoutClick(): void {
    this.userService.logout()
      .pipe(first())
      .subscribe(() => this.router.navigate(routePaths.login));
  }
}
