import { Injectable } from '@angular/core';

import { ProjectUserPermission, ProjectUserPermissionEdit } from '../../models/project-user-permission';

import { ProjectUserPermissionsDto, ProjectUserPermissionsEditDto } from './dto/project-user-permissions.dto';
import { IMapperFromDto } from './mappers';
import { ProjectUserRolesMapper } from './project-user-roles.mapper';

/** Mapper for Project permissions for some user from list. */
@Injectable({
  providedIn: 'root',
})
export class ProjectUserPermissionsMapper implements IMapperFromDto<ProjectUserPermissionsDto, ProjectUserPermission> {
  public constructor(
    private readonly projectUserRoleMapper: ProjectUserRolesMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: ProjectUserPermissionsDto): ProjectUserPermission {
    return new ProjectUserPermission({
      id: dto.projectId,
      roles: this.projectUserRoleMapper.fromDto(dto),
    });
  }

  /** @inheritdoc */
  public toDto(data: ProjectUserPermissionEdit): ProjectUserPermissionsEditDto {
    return {
      userId: data.userId,
      ...this.projectUserRoleMapper.toProjectUserPermissionFlagsDto(data.role),
    };
  }
}
