import { Injectable } from '@angular/core';

import { SortDirection } from '../../enums/sort-direction';
import { Sort } from '../../models/sort';

import { SortDto } from './dto/sort.dto';
import { IMapperToDto } from './mappers';

/** Mapper for Sort. */
@Injectable({ providedIn: 'root' })
export class SortMapper implements IMapperToDto<SortDto | undefined, Sort | undefined> {
  /** @inheritDoc */
  public toDto(sort: Sort | undefined): SortDto | undefined {
    if (!sort || sort.direction === SortDirection.NONE) {
      return;
    }

    // For descending sort use field name with direction (e.g. 'field_name:desc').
    return {
      OrderBy: sort.direction === SortDirection.ASC ? sort.field : `${sort.field}:desc`,
    };
  }
}
