import { Injectable } from '@angular/core';

import { ProjectUserPermissionEdit, ProjectUserProfilePermission } from '../../models/project-user-permission';

import { ProjectUserPermissionFlagsDto, ProjectUserProfilePermissionsDto } from './dto/project-user-permissions.dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { ProjectUserRolesMapper } from './project-user-roles.mapper';

/** Mapper for Project permissions for single user. */
@Injectable({
  providedIn: 'root',
})
export class ProjectUserProfilePermissionsMapper implements
  IMapperFromDto<ProjectUserProfilePermissionsDto, ProjectUserProfilePermission>,
  IMapperToDto<ProjectUserPermissionFlagsDto, ProjectUserPermissionEdit> {

  public constructor(
    private readonly projectUserRoleMapper: ProjectUserRolesMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: ProjectUserProfilePermissionsDto): ProjectUserProfilePermission {
    return new ProjectUserProfilePermission({
      id: dto.id,
      name: dto.name,
      organizationId: dto.organizationId,
      organizationName: dto.organizationName,
      roles: this.projectUserRoleMapper.fromDto(dto),
    });
  }

  /** @inheritdoc */
  public toDto(data: ProjectUserPermissionEdit): ProjectUserPermissionFlagsDto {
    return this.projectUserRoleMapper.toProjectUserPermissionFlagsDto(data.role);
  }
}
