import { OrganizationApproval } from './organization-approval';
import { OrganizationUserProfilePermission } from './organization-user-permission';
import { ProjectUserProfilePermission } from './project-user-permission';
import { UserBase } from './user-base';

/** User profile. */
export class UserProfile extends UserBase {

  /** Whether use has Global Admin permissions. */
  public readonly isGlobalAdmin: boolean;

  /** Organizations. */
  public readonly organizations: readonly OrganizationUserProfilePermission[];

  /** Projects. */
  public readonly projects: readonly ProjectUserProfilePermission[];

  /** Organization approvals. */
  public readonly organizationApprovals: readonly OrganizationApproval[];

  public constructor(data: UserProfileInitArgs) {
    super(data);
    this.isGlobalAdmin = data.isGlobalAdmin;
    this.organizations = data.organizations;
    this.projects = data.projects;
    this.organizationApprovals = data.organizationApprovals;
  }
}

type UserProfileInitArgs = UserProfile;
