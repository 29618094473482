<mat-selection-list [formControl]="userRolesControl">
  <mat-list-option
    *ngFor="let role of options; trackBy: userRole.trackBy"
    checkboxPosition="before"
    color="primary"
    [value]="role"
    [disabled]="disabled"
  >
    <div class="checkbox-name" [title]="userRole.toReadable(role)">
      {{ userRole.toReadable(role) }}
    </div>
    <div
      class="checkbox-description line-clamp"
      matTooltipClass="role-description-tooltip"
      [matTooltip]="userRole.getRoleDescription(role)"
    >
      {{ userRole.getRoleDescription(role) }}
    </div>
  </mat-list-option>
</mat-selection-list>
