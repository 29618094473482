<p class="values-list__label">Options list:</p>
<form
  class="values-list__input-container"
  [formGroup]="editForm"
  (ngSubmit)="saveOption()"
>
  <mat-form-field class="no-padding values-list__input">
    <input
      matInput
      type="text"
      placeholder="New Option"
      formControlName="optionValue"
    />
  </mat-form-field>
  <button mat-stroked-button type="submit" class="values-list__button">
    {{ editForm.controls.id.value ? "Save" : "Add" }}
  </button>
</form>
<mat-chip-listbox class="options-container">
  <mat-chip-option
    *ngFor="let item of optionsMap.entries(); trackBy: trackByIndex"
    class="options-container__chip"
    [selectable]="false"
    [removable]="true"
    [selected]="editForm.controls.id.value === item[0]"
    (click)="toggleEdit(item[0])"
    (removed)="removeAt(item[0])"
  >
    <span class="options-container__chip-content" [title]="item[1]">
      {{ item[1] }}
    </span>
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip-option>
</mat-chip-listbox>
