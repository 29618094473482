<mat-radio-group class="radio-group" [formControl]="userRoleControl">
  <mat-radio-button
    *ngFor="
      let role of projectUserRole.toArray();
      trackBy: projectUserRole.trackBy
    "
    class="option"
    [value]="role"
  >
    <div class="option-content">
      <span class="option-content__title">
        {{ projectUserRole.toReadable(role) }}
      </span>
      <div
        class="option-description line-clamp"
        matTooltipClass="role-description-tooltip"
        [matTooltip]="projectUserRole.toDescription(role)"
      >
        {{ projectUserRole.toDescription(role) }}
      </div>
    </div>
  </mat-radio-button>
</mat-radio-group>
