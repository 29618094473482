import { Injectable } from '@angular/core';

import { UserRole } from '../../enums/user-role';

import { OrganizationUserPermissionFlagsDto } from './dto/organization-user-permissions.dto';
import { IMapper } from './mappers';

const flagsToUserRoleMap: Record<keyof OrganizationUserPermissionFlagsDto, UserRole> = {
  isAdmin: UserRole.OrganizationAdmin,
  isUserAdmin: UserRole.UserAdmin,
  isManager: UserRole.Manager,
  isDeveloper: UserRole.Developer,
  isSalesManager: UserRole.SalesManager,
  isSalesPerson: UserRole.SalesPerson,
  isProjectEngineer: UserRole.ProjectEngineer,
  isProjectTechnician: UserRole.ProjectTechnician,
  isProjectCxEngineer: UserRole.ProjectCxEngineer,
};

/** Mapper for Organization User Roles. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationUserRolesMapper implements IMapper<OrganizationUserPermissionFlagsDto, UserRole[]> {
  /** @inheritdoc */
  public fromDto(dto: OrganizationUserPermissionFlagsDto): UserRole[] {
    return Object.entries(dto)
      .map(([key, hasRole]) => {
        const role = flagsToUserRoleMap[key as keyof OrganizationUserPermissionFlagsDto];
        return hasRole === true ? role : undefined;
      })
      .filter((item): item is UserRole => item !== undefined);
  }

  /** @inheritdoc */
  public toDto(roles: readonly UserRole[]): OrganizationUserPermissionFlagsDto {
    return {
      isAdmin: roles.includes(UserRole.OrganizationAdmin),
      isUserAdmin: roles.includes(UserRole.UserAdmin),
      isManager: roles.includes(UserRole.Manager),
      isDeveloper: roles.includes(UserRole.Developer),
      isSalesManager: roles.includes(UserRole.SalesManager),
      isSalesPerson: roles.includes(UserRole.SalesPerson),
      isProjectEngineer: roles.includes(UserRole.ProjectEngineer),
      isProjectTechnician: roles.includes(UserRole.ProjectTechnician),
      isProjectCxEngineer: roles.includes(UserRole.ProjectCxEngineer),
    };
  }
}
