import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Confirmation card. */
@Component({
  selector: 'atsdartw-confirmation-card',
  templateUrl: './confirmation-card.component.html',
  styleUrls: ['./confirmation-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationCardComponent {

  /** Card title. */
  @Input()
  public title = '';

  /** Confirm register result. */
  @Input()
  public confirmResult: string | null = '';

  /** Confirm register error. */
  @Input()
  public confirmError: string | null = '';
}
