import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

/** Time for display notification. */
const TIME_DISPLAY = 5000;

/** Notification service.*/
@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  public constructor(private readonly toastrService: ToastrService) { }

  /**
   * Show notification with success message.
   * @param message Success message.
   * @param title Title message.
   */
  public showSuccess(message: string, title?: string): void {
    this.toastrService.success(message, title, { timeOut: TIME_DISPLAY });
  }

  /**
   * Show notification with error message.
   * @param message Error message.
   */
  public showError(message: string): void {
    this.toastrService.error(message, '', { timeOut: TIME_DISPLAY });
  }
}
