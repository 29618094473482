import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';
import { SimpleValueAccessor, controlProviderFor } from '@atsdart/common/core/utils/value-accessor';

/** User role radio buttons. */
@Destroyable()
@Component({
  selector: 'atsdartw-user-roles-radio-buttons',
  templateUrl: './user-roles-radio-buttons.component.html',
  styleUrls: ['./user-roles-radio-buttons.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => UserRolesRadioButtonsComponent)],
})
export class UserRolesRadioButtonsComponent extends SimpleValueAccessor<UserRole | null> implements OnInit {
  /** Options. */
  @Input()
  public options = UserRole.toArray();

  private readonly fb = inject(NonNullableFormBuilder);

  /** User role. */
  protected readonly userRole = UserRole;

  /** User role form control. */
  protected readonly userRoleControl = this.fb.control<UserRole | null>(null);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: UserRole | null): void {
    this.userRoleControl.setValue(value, { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.userRoleControl.disable();
    } else {
      this.userRoleControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  private subscribeToControlChanges(): void {
    this.userRoleControl.valueChanges.pipe(
      takeUntilDestroy(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }
}
