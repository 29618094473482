import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest, switchMap } from 'rxjs';
import { CurrentOrganizationAdminPermission, CurrentOrganizationService } from '@atsdart/common/core/services/current-organization.service';
import { UserAuthService } from '@atsdart/common/core/services/user-auth.service';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

/** Confirm delete user dialog service. */
@Injectable({
  providedIn: 'root',
})
export class ConfirmDeleteUserDialogService {

  private readonly currentOrganizationService = inject(CurrentOrganizationService);

  private readonly userAuthService = inject(UserAuthService);

  private readonly dialogService = inject(MatDialog);

  /**
   * Open dialog.
   * @param data Data dialog.
   */
  public openDialog(): Observable<boolean> {
    return combineLatest([
      this.currentOrganizationService.organization$,
      this.currentOrganizationService.userAdminPermission$,
    ])
      .pipe(
        switchMap(([organization, userAdminPermission]) => {
          let action = 'Disable';
          let message = 'disable this user from the organization?';

          if (userAdminPermission === CurrentOrganizationAdminPermission.GlobalAdmin) {
            message = 'disable this user?';
          } else if (userAdminPermission === CurrentOrganizationAdminPermission.OrganizationAdmin) {
            action = 'Remove';
            message = `remove this user from \n the "${organization?.name}" Organization? \nThis cannot be undone.`;
          }

          return this.dialogService.open(ConfirmDialogComponent, {
            data: {
              title: `${action} User?`,
              text: `Are you sure you want to ${message}`,
              confirmButtonText: action,
            },
            autoFocus: false,
          })
            .afterClosed();
        }),
      );
  }
}
