/** Organization invitation. */
export class OrganizationInvitation {
  /** Organization id. */
  public readonly id: number;

  /** Organization name. */
  public readonly name: string;

  public constructor(data: OrganizationInvitationInitArgs) {
    this.id = data.id;
    this.name = data.name;
  }
}

type OrganizationInvitationInitArgs = OrganizationInvitation;
