/** Route paths. */
export const routePaths = {
  home: ['/'],

  // Register module.
  registerConfirm: ['/register/confirm'],

  // Auth module.
  login: ['/auth/login'],
  forgotPassword: ['/auth/reset-password'],
  resetPassword: ['/auth/confirm-password'],

  // Authority Management module.
  authorityManagement: ['/authority-management'],
  usersManagement: ['/authority-management/users'],
  createUser: ['/authority-management/users/create'],
  editUser: (id: number) => [`/authority-management/users/edit/${id}`],
  projects: ['/authority-management/projects'],
  projectManagement: (projectId: number) => [`/authority-management/projects/${projectId}`],
  projectAccessManagement: ['/authority-management/project-access'],
  projectAccessEdit: (projectId: number) => [`/authority-management/project-access/${projectId}`],
  projectRoleEdit: (projectId: number, userId: number) => [`/authority-management/project-access/${projectId}/edit-role/${userId}`],

  // Organizations module.
  organizations: ['/organizations'],
  editOrganization: (id: number) => [`/organizations/edit/${id}`],
  createOrganization: [`/organizations/create`],

  // Organization Account Settings module.
  organization: ['/organization-account-settings'],

  // Resources module.
  resources: ['/resources'],

  // Resources module - ports section.
  ports: ['/resources/ports'],
  createPortType: ['/resources/ports/create'],
  editPortType: (id: number) => [`/resources/ports/edit/${id}`],

  // Resources module - components section.
  components: [`/resources/components`],
  grantedOrganizationWithComponents: (organizationId: number) => [`/resources/components/${organizationId}`],
  editRecipientOrganizationWithComponents: (organizationId: number, recipientOrganizationId: number) =>
    [`/resources/components/${organizationId}/edit/${recipientOrganizationId}`],
  createOrganizationComponent: (organizationId: number, recipientOrganizationId: number) =>
    [...routePaths.editRecipientOrganizationWithComponents(organizationId, recipientOrganizationId), 'create'],
  editOrganizationComponent: (organizationId: number, recipientOrganizationId: number, componentId: string) =>
    [...routePaths.editRecipientOrganizationWithComponents(organizationId, recipientOrganizationId), 'edit', componentId],

  // Resources module - diagrams section.
  diagrams: [`/resources/diagrams`],
  grantedOrganizationWithDiagrams: (organizationId: number) => [`/resources/diagrams/${organizationId}`],
  editRecipientOrganizationWithDiagrams: (organizationId: number, recipientOrganizationId: number) =>
    [`/resources/diagrams/${organizationId}/edit/${recipientOrganizationId}`],
  createOrganizationDiagram: (organizationId: number, recipientOrganizationId: number) =>
    [...routePaths.editRecipientOrganizationWithDiagrams(organizationId, recipientOrganizationId), 'create'],
  editOrganizationDiagram: (organizationId: number, recipientOrganizationId: number, componentId: string) =>
    [...routePaths.editRecipientOrganizationWithDiagrams(organizationId, recipientOrganizationId), 'edit', componentId],

  // Template admin module.
  templateAdmin: ['/template-admin'],
  tutorialVideos: ['/template-admin/tutorial-videos'],
  editTutorialVideo: (id: number) => [`/template-admin/tutorial-videos/edit/${id}`],
  createTutorialVideo: [`/template-admin/tutorial-videos/create`],
  templatesManagement: ['/template-admin/templates-management'],
  editTemplate: (id: number) => [`/template-admin/templates-management/edit/${id}`],

  // Access error page.
  accessError: ['/access-error'],
};
