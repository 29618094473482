import { Injectable } from '@angular/core';

import { TagFilters } from '@atsdart/common/core/models/filters/tag-filters';
import { removeEmptyValues } from '@atsdart/common/core/utils/remove-empty-values';

import { IMapperToDto } from '../mappers';
import { TagFiltersDto } from '../dto/filters/tag-filters.dto';

/** Mapper for Tag filters. */
@Injectable({ providedIn: 'root' })
export class TagFiltersMapper implements IMapperToDto<TagFiltersDto, TagFilters> {
  /** @inheritdoc */
  public toDto(data?: TagFilters): TagFiltersDto {
    const filtersDto: TagFiltersDto = {
      OrganizationId: data?.organizationId,
      NameContains: data?.nameContains,
    };

    return removeEmptyValues(filtersDto);
  }
}
