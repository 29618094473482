import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleSubject } from '@atsdart/common/core/utils/rxjs/toggle-subject';

import { backdropAnimationTrigger, panelAnimationTrigger } from './side-panel-animations';

/**
 * Side panel component.\
 * Some panel properties might be overridden using CSS custom properties.
 * @example
 * ```css
 * atsdartc-side-panel {
 *   --panel-width: 900px;
 * }
 * ```
 */
@Component({
  selector: 'atsdartc-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    backdropAnimationTrigger,
    panelAnimationTrigger,
  ],
})
export class SidePanelComponent {
  /** Back link to redirect on close. */
  @Input()
  public backLink?: string[];

  /** Header title. */
  @Input()
  public headerTitle?: string;

  /** Don't close side panel when clicking on backdrop. */
  @Input()
  public isClosingBackdropDisabled?: boolean;

  /**
   * Handle escape key press.
   * @param _event Event.
   */
  @HostListener('document:keydown.escape', ['$event'])
  protected onKeydownHandler(_event: KeyboardEvent): void {
    this.closeByTriggerOnBackground();
  }

  /** Is side panel opened. */
  protected readonly isOpened$ = new ToggleSubject(true);

  public constructor(private readonly router: Router) { }

  /** Close side panel. */
  public closeSidePanel(): void {
    this.isOpened$.off();
  }

  /**
   * Redirect on panel close.
   * @param isOpened Is side panel opened.
   */
  protected redirectOnClose(isOpened: boolean): void {
    if (!isOpened && this.backLink) {
      this.router.navigate(this.backLink);
    }
  }

  /** Handle click on background. */
  protected onClickBackground(): void {
    this.closeByTriggerOnBackground();
  }

  private closeByTriggerOnBackground(): void {
    if (this.isClosingBackdropDisabled) {
      return;
    }
    this.closeSidePanel();
  }
}
