import { Injectable } from '@angular/core';

import { getDateWithoutTimezoneOffset, isInvalidDate } from '../../utils/date-time';

/** Mapper for Date.*/
@Injectable({ providedIn: 'root' })
export class DateMapper {

  /**
   * Gets date without user timezone offset.
   * @param value Value for get Date.
   */
  public getDateWithoutTimezone(value: Date | string): Date {
    const date = value instanceof Date ? value : new Date(value);
    if (isInvalidDate(date)) {
      throw Error;
    }
    return getDateWithoutTimezoneOffset(date);
  }
}
