<atsdartc-base-dialog titleDialog="Select Role(s)">
  <form [formGroup]="form">
    <div class="organization-roles">
      <div class="form-field">
        <span class="form-field__label"> Organization Role(s) </span>
        <div>
          <atsdartw-user-roles-select
            class="roles-select"
            formControlName="organizationRoles"
            [options]="userRole.toOrganizationRolesArray()"
          >
          </atsdartw-user-roles-select>
          <mat-error>
            <atsdartc-form-control-validation-message
              controlName="organizationRoles"
            >
            </atsdartc-form-control-validation-message>
          </mat-error>
        </div>
      </div>
      <div class="form-field project-roles">
        <span class="form-field__label">
          Default Project
          <br />
          Role(s)
        </span>
        <div>
          <atsdartw-user-roles-radio-buttons
            class="roles-select"
            formControlName="projectRole"
            [options]="userRole.toProjectRolesArray()"
          />
          <mat-error>
            <atsdartc-form-control-validation-message controlName="projectRole">
            </atsdartc-form-control-validation-message>
          </mat-error>
        </div>
      </div>
    </div>
  </form>
  <div dialogFooter class="dialog-footer">
    <button
      mat-stroked-button
      class="dialog-footer__button"
      type="button"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="dialog-footer__button"
      type="button"
      [atsdartcLoading]="isLoading$ | async"
      (click)="onSubmitForm()"
    >
      Save
    </button>
  </div>
</atsdartc-base-dialog>
