/**
 * Start auto download file by URL.
 * @param url Download URLю.
 * @param fileName File name.
 * @returns Whether file was downloaded successfully or not.
 */
export function autoDownloadFile(url: string, fileName?: string): boolean {
  const downloadWindow = window.open('about:blank', '_blank');

  if (downloadWindow != null) {
    const linkElement = downloadWindow.document.createElement('a');
    linkElement.href = url;

    if (fileName != null) {
      linkElement.download = fileName;
    }

    downloadWindow.document.body?.appendChild(linkElement);
    linkElement.click();

    setTimeout(() => {
      downloadWindow.close();
    }, 300);

    return true;
  }

  return false;
}
