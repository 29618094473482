import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { autoDownloadFile } from '@atsdart/common/core/utils/auto-download-file';

/** Download dialog data. */
export interface DownloadDialogData {

  /** Url. */
  readonly url: string;

  /** Filename. */
  readonly filename?: string;

  /** Should open file preview. */
  readonly shouldOpenFilePreview?: boolean;
}

/** Download dialog. */
@Component({
  selector: 'atsdartw-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadDialogComponent implements OnInit {

  private readonly sanitizer = inject(DomSanitizer);

  private readonly data = inject<DownloadDialogData>(MAT_DIALOG_DATA);

  private readonly dialogRef = inject(MatDialogRef);

  /** Download url. */
  protected readonly downloadUrl = this.sanitizer.bypassSecurityTrustUrl(this.data.url);

  /** Filename. */
  protected readonly filename = this.data.filename;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.startAutoDownload();
  }

  private startAutoDownload(): void {
    if (this.data.shouldOpenFilePreview) {
      this.openPreview();
      return;
    }

    if (autoDownloadFile(this.data.url, this.data.filename)) {
      this.dialogRef.close();
    }
  }

  private openPreview(): void {
    const previewWindow = window.open(this.data.url, '_blank');
    if (previewWindow != null) {
      this.dialogRef.close();
    }
  }
}
