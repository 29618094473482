
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Dialog component. */
@Component({
  selector: 'atsdartc-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {

  /** Title dialog. */
  @Input()
  public titleDialog = '';

}
