<atsdartc-base-dialog [titleDialog]="titleDialog">
  <p class="dialog-body">
    {{ infoDialog }}
  </p>
  <div dialogFooter class="dialog-actions">
    <button
      mat-stroked-button
      type="button"
      class="dialog-actions__button"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      type="button"
      class="dialog-actions__button"
      [mat-dialog-close]="true"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</atsdartc-base-dialog>
