import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserFilters, UserFiltersForm } from '@atsdart/common/core/models/filters/user-filters';

/** Members filters form. */
@Component({
  selector: 'atsdartw-members-table-filters',
  templateUrl: './members-table-filters.component.html',
  styleUrls: ['./members-table-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersTableFiltersComponent {

  /** Filters form group. */
  @Input()
  public filtersForm?: FormGroup<UserFiltersForm>;

  /** Is loading. */
  @Input()
  public isLoading = false;

  /** Event emitter when submit filters form . */
  @Output()
  protected readonly submitForm = new EventEmitter();

  /** Event emitter when clear form field. */
  @Output()
  protected readonly clearFormField = new EventEmitter();

  /** Handles submit filters form. */
  protected onSubmitForm(): void {
    if (this.filtersForm) {
      this.submitForm.emit(this.filtersForm.getRawValue());
    }
  }

  /**
   *  Handles clear form text input.
   *  @param fieldName Form control name.
   */
  protected onClearText(fieldName: keyof UserFilters): void {
    if (this.filtersForm) {
      this.filtersForm?.controls[fieldName]?.reset();
      this.onSubmitForm();
    }
  }
}
