import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleSubject } from '@atsdart/common/core/utils/rxjs/toggle-subject';
import { trackByIndex } from '@atsdart/common/core/utils/trackby';

import { NavItem } from './navigation-item';

/** Navigation list item component. */
@Component({
  selector: 'atsdartw-nav-list-item',
  templateUrl: './nav-list-item.component.html',
  styleUrls: ['./nav-list-item.component.css'],

  // Active route detection is not working using OnPush strategy.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavListItemComponent implements OnInit {
  /** Navigation item. */
  @Input()
  public item: NavItem | null = null;

  /** Navigation item depth. */
  @Input()
  public depth = 0;

  /** Is navigation menu collapsed. */
  @Input()
  public collapsed = false;

  /** Navigate to group's first child route on expand click. */
  @Input()
  public openFirstChild = false;

  /** Is group expanded. */
  protected readonly isExpanded$ = new ToggleSubject();

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  public constructor(private readonly router: Router) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const isActive = this.isItemActive(this.item);
    const isExpandedByDefault = this.item?.expandedByDefault ?? false;
    this.isExpanded$.next(isActive || isExpandedByDefault);
  }

  /**
   * Handle item selection.
   * @param item Item.
   * @param expanded Expanded,.
   */
  protected onItemSelected(item: NavItem, expanded: boolean): void {
    if (!item.children?.length || this.collapsed) {
      this.router.navigate([item.path]);
    }
    if (item?.children?.length) {
      this.isExpanded$.toggle();

      if (this.openFirstChild && !expanded && item.children[0].path) {
        this.router.navigate([item.children[0].path]);
      }
    }
  }

  /**
   * Check whether the item is a part of current route.
   * @param item Item.
   */
  protected isItemActive(item: NavItem | null): boolean {
    return item?.path ? this.router.isActive(item.path, {
      paths: (item.children?.length ?? item.hasImplicitChildren) ? 'subset' : 'exact',
      queryParams: 'subset',
      matrixParams: 'ignored',
      fragment: 'ignored',
    }) : false;
  }
}
