import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { UserRole } from '../../enums/user-role';
import { FetchListOptions } from '../../models/fetch-list-options';
import { OrganizationFilters } from '../../models/filters/organization-filter';
import { UserFilters } from '../../models/filters/user-filters';
import { Organization, EditOrganization, CreateOrganization } from '../../models/organization';
import { PagedList } from '../../models/paged-list';
import { User } from '../../models/user';
import { AppUrlsConfig } from '../app-urls.config';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { OrganizationDto } from '../mappers/dto/organization.dto';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { OrganizationUserDto } from '../mappers/dto/user.dto';
import { OrganizationFiltersMapper } from '../mappers/filter-mappers/organization-filters.mapper';
import { UserFiltersMapper } from '../mappers/filter-mappers/user-filters.mapper';
import { HttpParamsMapper } from '../mappers/http-params.mapper';
import { OrganizationUserMapper } from '../mappers/organization-user.mapper';
import { OrganizationMapper } from '../mappers/organization.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { OrganizationUserRolesMapper } from '../mappers/organization-user-roles.mapper';
import { OrganizationUser } from '../../models/organization-user';
import { EditUserOrganizationApproval, UserOrganizationApproval } from '../../models/user-organization-approval';
import { OrganizationUserApprovalMapper } from '../mappers/organization-user-approval.mapper';
import { UserOrganizationApprovalListDto } from '../mappers/dto/organization-user-approval.dto';
import { OrganizationApprovalFiltersMapper } from '../mappers/filter-mappers/organization-approval-filters.mapper';
import { OrganizationApprovalFilters } from '../../models/filters/organization-approval-filters';
import { OrganizationStatusFilter } from '../../enums/organization-status-filter';

/** Organizations API service. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService {
  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly http: HttpClient,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly listMapper: PagedListMapper,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly organizationUserMapper: OrganizationUserMapper,
    private readonly organizationUserApprovalMapper: OrganizationUserApprovalMapper,
    private readonly userFilterMapper: UserFiltersMapper,
    private readonly organizationMapper: OrganizationMapper,
    private readonly organizationFiltersMapper: OrganizationFiltersMapper,
    private readonly userRolesMapper: OrganizationUserRolesMapper,
    private readonly organizationApprovalFiltersMapper: OrganizationApprovalFiltersMapper,
  ) { }

  /**
   * Get list of users.
   * @param options Request options.
   * @param organizationId Organization id.
   */
  public getUsersList(options: FetchListOptions<UserFilters>, organizationId: number): Observable<PagedList<OrganizationUser>> {
    const params = this.paramsMapper.toDto(options, this.userFilterMapper);
    return this.http.get<PagedListDto<OrganizationUserDto>>(this.apiUrls.organizationsApi.getUsersList(organizationId), { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.organizationUserMapper,
        options.pagination,
      )),
    );
  }

  /**
   * Delete user.
   * @param organizationId Organization id.
   * @param userId User id.
   * @returns Success message for the user.
   */
  public deleteUser(organizationId: number, userId: number): Observable<string> {
    return this.http.delete<void>(this.apiUrls.organizationsApi.getUserById(organizationId, userId)).pipe(
      map(() => 'User has been successfully deleted from the organization'),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /** Get organizations with status "Active" list. */
  public getActiveOrganizationList(): Observable<Organization[]> {
    return this.getOrganizationPagedList({
      filter: {
        organizationStatus: OrganizationStatusFilter.Active,
      },
    }).pipe(
      map(list => list.items),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get organization paged list.
   * @param options Fetch options.
   */
  public getOrganizationPagedList(options: FetchListOptions<OrganizationFilters>): Observable<PagedList<Organization>> {
    const params = this.paramsMapper.toDto(options, this.organizationFiltersMapper);

    return this.http.get<PagedListDto<OrganizationDto>>(this.apiUrls.organizationsApi.getList, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.organizationMapper,
        options.pagination,
      )),
    );
  }

  /**
   * Create organization.
   * @param organization Organization info.
   * @param logoImageFile Logo image file.
   * @returns Success message for the user.
   */
  public createOrganization(organization: CreateOrganization): Observable<string> {
    const formData = new FormData();

    if (organization.logoImageFile) {
      formData.append('LogoImageFile', organization.logoImageFile);
    }

    const dto = this.organizationMapper.toDto(organization);
    formData.append('organizationModel', JSON.stringify(dto));

    return this.http.post<void>(this.apiUrls.organizationsApi.createOrganization, formData).pipe(
      map(() => 'Organization created.'),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        errorData => this.organizationMapper.validationErrorLogoImageFileFromDto(errorData),
      ),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.organizationMapper,
      ),
    );
  }

  /**
   * Add user to organization.
   * @param id Organization id.
   * @param value User.
   * @param userRoles User riles.
   * @returns Success message for the user.
   */
  public addUserToOrganization(id: number, value: User, userRoles: UserRole[]): Observable<string> {
    const dto = this.organizationMapper.toOrganizationEditUserDto(value, userRoles);
    return this.http.post<void>(this.apiUrls.organizationsApi.addUser(id), dto).pipe(
      map(() => 'User has been successfully added to the organization.'),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get organization by id.
   * @param id Organization id.
   */
  public getOrganizationById(id: number): Observable<Organization> {
    return this.http.get<OrganizationDto>(this.apiUrls.organizationsApi.getById(id)).pipe(
      map(dto => this.organizationMapper.fromDto(dto, id)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update organization.
   * @param organizationId Organization id.
   * @param value Organization info.
   * @returns Success message for the user.
   */
  public updateOrganization(organizationId: number, value: EditOrganization): Observable<string> {
    const dto = this.organizationMapper.toDto(value);
    return this.http.put<void>(this.apiUrls.organizationsApi.updateOrganization(organizationId), dto).pipe(
      map(() => 'Organization updated.'),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.organizationMapper,
      ),
    );
  }

  /**
   * Delete organization.
   * @param organizationId Organization id.
   * @returns Success message for the user.
   */
  public deleteOrganization(organizationId: number): Observable<string> {
    return this.http.delete<void>(this.apiUrls.organizationsApi.deleteOrganization(organizationId)).pipe(
      map(() => 'Organization deleted.'),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Restore organization.
   * @param organizationId Organization id.
   * @returns Success message for the user.
   */
  public restoreOrganization(organizationId: number): Observable<string> {
    return this.http.put<void>(this.apiUrls.organizationsApi.restoreOrganization(organizationId), null).pipe(
      map(() => 'Organization is active.'),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update user roles in organization.
   * @param organizationId Organization id.
   * @param userId User id.
   * @param userRoles User roles.
   * @returns Success message for the user.
   */
  public updateUserRolesInOrganization(organizationId: number, userId: number, userRoles: UserRole[]): Observable<string> {
    const dto = this.userRolesMapper.toDto(userRoles);
    return this.http.put<void>(this.apiUrls.organizationsApi.updateUserInOrganization(organizationId, userId), dto).pipe(
      map(() => 'User roles updated.'),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update user organization approval.
   * @param params Params.
   * @param params.organizationId Organization id.
   * @param params.approvalId Approval id.
   * @param params.organizationApproval User organization approval.
   * @returns Success message for the user.
   */
  public updateUserOrganizationApproval(params: {
    organizationId: number;
    approvalId: number;
    organizationApproval: EditUserOrganizationApproval;
  }): Observable<string> {
    const dto = this.organizationUserApprovalMapper.toDto(params.organizationApproval);
    return this.http.put<void>(
      this.apiUrls.organizationsApi.updateUserOrganizationApproval(params.organizationId, params.approvalId), dto,
    ).pipe(
      map(() => 'User approval status updated.'),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.organizationUserApprovalMapper,
      ),
    );
  }

  /**
   * Get user organization approval by id.
   * @param organizationId Organization id.
   * @param options Filters organization approval.
   */
  public getUserOrganizationApprovalById(
    organizationId: number,
    options: OrganizationApprovalFilters,
  ): Observable<UserOrganizationApproval[]> {
    const filter = this.organizationApprovalFiltersMapper.toDto(options);
    const params = this.paramsMapper.toDto({ filter });
    return this.http
      .get<UserOrganizationApprovalListDto>(
      this.apiUrls.organizationsApi.getUserOrganizationApprovalById(
        organizationId,
      ),
      { params },
    )
      .pipe(
        map(dtos => dtos.items.map(dto => this.organizationUserApprovalMapper.fromDto(dto))),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Upload organization logo.
   * @param organizationId Organization id.
   * @param file File.
   * @returns Success message for the user.
   */
  public uploadOrganizationLogo(organizationId: Organization['id'], file: File): Observable<void> {
    const formData = new FormData();
    formData.append('LogoImageFile', file);

    return this.http
      .put<void>(
      this.apiUrls.organizationsApi.updateOrganizationLogo(organizationId),
      formData,
    )
      .pipe(
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          errorData => this.organizationMapper.validationErrorLogoImageFileFromDto(errorData),
        ),
      );
  }

  /**
   * Delete organization logo.
   * @param organizationId Organization id.
   */
  public deleteOrganizationLogo(organizationId: Organization['id']): Observable<void> {
    return this.http
      .delete<void>(
      this.apiUrls.organizationsApi.deleteOrganizationLogo(organizationId),
    )
      .pipe(
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }
}
