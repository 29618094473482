import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from 'projects/web/src/app/features/shared/components/confirm-dialog/confirm-dialog-data';
import { ConfirmDialogComponent } from 'projects/web/src/app/features/shared/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

/** Confirm dialog service.*/
@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  public constructor(private readonly dialogService: MatDialog) { }

  /**
   * Open dialog.
   * @param data Data dialog.
   */
  public openDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.dialogService
      .open(ConfirmDialogComponent, {
        data,
        autoFocus: false,
      })
      .afterClosed();
  }
}
