import { Injectable } from '@angular/core';

import { IMapperToDto } from '../mappers';

/** Mapper for Filters. */
@Injectable({ providedIn: 'root' })
export class FilterMapper<T> implements IMapperToDto<Record<string, string> | undefined, T | undefined> {
  /** @inheritDoc */
  public toDto(filters: T | undefined): Record<string, string> | undefined {
    if (!filters) {
      return;
    }

    const result: Record<string, string> = {};

    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const filter = filters[key];

        // Compare to null is required to allow 0 value.
        if (filter != null && String(filter) !== '') {
          result[key] = String(filter);
        }
      }
    }

    return result;
  }
}
