import { Observable, BehaviorSubject, combineLatest, merge, Subject, of, ReplaySubject } from 'rxjs';
import { map, withLatestFrom, tap, switchMap, shareReplay, debounceTime, startWith, catchError } from 'rxjs/operators';

import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { PaginationData } from '../models/pagination-data';
import { Sort } from '../models/sort';

import { toggleExecutionState } from './rxjs/toggle-execution-state';
import { ToggleSubject } from './rxjs/toggle-subject';

type FetchItemsApiRequest<TItem, TFilter> = (
  options: FetchListOptions<TFilter>,
) => Observable<PagedList<TItem>>;

/** Provide API to handle new items in the PagedList. */
interface IListHandleStrategy<T> {

  /** Handle paginated list according to some rules. */
  handle(paginatedList: PagedList<T>): T[];
}

/**
 * Handler for "Infinite scroll" functionality.
 *
 * The point is to concatenate items from new PagedList with the previous one
 * until "PagedList.pagination.page" does not equal 1.
 */
export class InfiniteScrollListStrategy<T> implements IListHandleStrategy<T> {

  private list: T[] = [];

  /** @inheritdoc */
  public handle(paginatedList: PagedList<T>): T[] {
    if (paginatedList.pagination.page === 1) {
      this.list = paginatedList.items;
    } else {
      this.list = this.list.concat(paginatedList.items);
    }
    return this.list;
  }
}

/**
 * Handle for "table" functionality.
 * Common strategy that just return items from received "PagedList" instance.
 */
export class TableListStrategy<T> implements IListHandleStrategy<T> {

  /** @inheritdoc */
  public handle(paginatedList: PagedList<T>): T[] {
    return paginatedList.items;
  }
}

/** Data for ListManager constructor. */
interface ListManagerInitParams<TItem, TFilter> {

  /** List strategy. */
  readonly strategy: IListHandleStrategy<TItem>;

  /** List of filters. */
  readonly filter$?: Observable<TFilter>;

  /** Sorting. */
  readonly sort$?: Observable<Sort | undefined>;

  /** Pagination. */
  readonly pagination?: PaginationData;

  /** Equality function. */
  readonly equalityFunc?: (first: TItem, second: TItem) => boolean;

  /**
   * Whether initial filters should be skipped.
   * It means no values will be emitted until filters are manually updated using 'filtersChanged'.
   * If you pass filter$ stream during initialization then this option won't affect it.
   */
  readonly skipInitialFilters?: boolean;
}

/* eslint-disable rxjs/no-subject-value */
/**
 * Provide functionality to work with lists.
 * Handle pagination, filters and sorting.
 */
export class ListManager<TItem, TFilter = {}> {

  /** Emits information about page pagination. */
  public readonly pagePagination$: Observable<PaginationData>;

  /** Emits value of selected filters. */
  public readonly filter$: Observable<TFilter | undefined>;

  /** Emits value of selected sort. */
  public readonly sort$: Observable<Sort | undefined>;

  /** List loading state. */
  public readonly listLoading$: Observable<boolean>;

  /** Current value. */
  public readonly currentValue$: Observable<TItem[]>;

  private readonly loading$ = new ToggleSubject(true);

  private readonly reload$ = new BehaviorSubject<void>(undefined);

  private readonly pagination$: BehaviorSubject<PaginationData>;

  private readonly sortValue$ = new BehaviorSubject<Sort | undefined>(undefined);

  private readonly filterValue$ = new ReplaySubject<TFilter | undefined>(1);

  private readonly resetPaginationParams$: Observable<[Sort | undefined, TFilter | undefined]>;

  private readonly updatedValue$ = new Subject<TItem>();

  private readonly currentValueInner$ = new BehaviorSubject<TItem[]>([]);

  private readonly listStrategy: IListHandleStrategy<TItem>;

  private readonly equalityFunc: (first: TItem, second: TItem) => boolean;

  /** Is first page. */
  public get isFirstPage(): boolean {
    return this.pagination$.value.page === 1;
  }

  /** Is last page. */
  public get isLastPage(): boolean {
    const pagination = this.pagination$.value;
    return pagination.page * pagination.pageSize >= pagination.totalCount;
  }

  /** Pagination options. */
  public get paginationOptions(): PaginationData {
    return this.pagination$.value;
  }

  /**
   * Please note that list manager will use its own filter and sort
   * streams if you don't provide them in constructor.
   * @param options Options.
   */
  public constructor(options: ListManagerInitParams<TItem, TFilter>) {
    this.listStrategy = options.strategy;
    this.equalityFunc = options?.equalityFunc ?? this.defaultEqualityFunction;
    this.filter$ = options?.filter$ ?? this.filterValue$.asObservable();
    this.sort$ = options?.sort$ ?? this.sortValue$.asObservable();
    this.pagination$ = new BehaviorSubject(options?.pagination ?? new PaginationData());

    this.currentValue$ = this.currentValueInner$.asObservable();
    this.listLoading$ = this.loading$.asObservable();

    if (options.skipInitialFilters !== true) {
      this.filterValue$.next(undefined);
    }

    this.resetPaginationParams$ = combineLatest([
      this.sort$,
      this.filter$,
    ]).pipe(
      debounceTime(400),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.pagePagination$ = merge(
      this.pagination$,
      this.resetPaginationParams$.pipe(
        map(() => this.getDefaultPagination()),
      ),
    ).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Get list of paginated items from server.
   * Note: to display data fetch errors you should catch the error inside 'func' argument.
   * Be aware that all errors are handled internally. So getPaginatedItems doesn't emit any errors related to data fetching.
   * @param func Api request function.
   * @returns Paginated items list.
   */
  public getPaginatedItems(func: FetchItemsApiRequest<TItem, TFilter>): Observable<TItem[]> {
    return this.reload$.pipe(
      switchMap(() => this.resetPaginationParams$),
      withLatestFrom(this.pagePagination$),
      switchMap(([[sort, filter], pagination]) => this.requestData(func, { sort, filter, pagination })),
      tap(pagedList => this.paginationChanged(pagedList.pagination)),
      map(list => this.listStrategy.handle(list)),
      switchMap(list => this.initUpdateValueStream(list)),
      tap(list => this.currentValueInner$.next(list)),
    );
  }

  /**
   * Sort changed.
   * @param sort Updated sort.
   */
  public sortChanged(sort?: Sort): void {
    this.sortValue$.next(sort);
  }

  /**
   * Filters changed.
   * @param filters Updated filters.
   */
  public filtersChanged(filters?: TFilter): void {
    this.filterValue$.next(filters);
  }

  /**
   * Update item in list.\
   * WARNING: It won't work until you provide equality function for List manager.
   * Also be careful as this method don't update pagination and sorting in list.
   * @param item Item.
   */
  public updateItem(item: TItem): void {
    this.updatedValue$.next(item);
  }

  /**
   * Pagination changed.
   * @param pagination Updated pagination.
   * @param triggerReload Should reload the list.
   */
  public paginationChanged(pagination: PaginationData, triggerReload = false): void {
    this.pagination$.next(pagination);
    if (triggerReload) {
      this.reload$.next();
    }
  }

  /** Manually reload current page. */
  public reloadCurrentPage(): void {
    this.reload$.next();
  }

  /** Go to next page. */
  public nextPage(): void {
    const currentPagination = this.pagination$.value;
    if (!this.isLastPage) {
      const nextPagination = new PaginationData({
        page: this.pagination$.value.page + 1,
        pageSize: currentPagination.pageSize,
      });
      this.paginationChanged(nextPagination, true);
    }
  }

  /** Go to prev page. */
  public prevPage(): void {
    const currentPagination = this.pagination$.value;
    if (!this.isFirstPage) {
      const nextPagination = new PaginationData({
        page: this.pagination$.value.page - 1,
        pageSize: currentPagination.pageSize,
      });
      this.paginationChanged(nextPagination, true);
    }
  }

  /** Pagination changed. */
  public returnToFirstPage(): void {
    const nextPagination = this.getDefaultPagination();
    this.paginationChanged(nextPagination, true);
  }

  /**
   * Request data.
   * @param func Function to use for data request.
   * @param options Options.
   */
  private requestData(func: FetchItemsApiRequest<TItem, TFilter>, options: FetchListOptions<TFilter>): Observable<PagedList<TItem>> {
    return func(options).pipe(
      toggleExecutionState(this.loading$),
      catchError(() => of(new PagedList({
        pagination: options.pagination ?? new PaginationData(),
        items: this.currentValueInner$.value,
      }))),
    );
  }

  /** Reset pagination. */
  private getDefaultPagination(): PaginationData {
    const currentPagination = this.pagination$.value;
    return new PaginationData({
      page: 1,
      pageSize: currentPagination.pageSize,
      totalCount: currentPagination.totalCount,
    });
  }

  private initUpdateValueStream(list: TItem[]): Observable<TItem[]> {
    return this.updatedValue$.pipe(
      startWith(null),
      map(updatedValue => {
        if (updatedValue) {
          const index = list.findIndex(item => this.equalityFunc(item, updatedValue));
          if (index !== -1) {
            list[index] = updatedValue;
          }
        }

        return [...list];
      }),
    );
  }

  private defaultEqualityFunction(first: TItem, second: TItem): boolean {
    return first === second;
  }
}
