import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { Destroyable, takeUntilDestroy } from '@atsdart/common/core/utils/destroyable';
import { controlProviderFor, SimpleValueAccessor } from '@atsdart/common/core/utils/value-accessor';

/** User roles select. */
@Component({
  selector: 'atsdartw-user-roles-select',
  templateUrl: './user-roles-select.component.html',
  styleUrls: ['./user-roles-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => UserRolesSelectComponent)],
})
@Destroyable()
export class UserRolesSelectComponent extends SimpleValueAccessor<UserRole[]> implements OnInit {
  /** Options. */
  @Input()
  public options = UserRole.toArray();

  /** User role. */
  protected readonly userRole = UserRole;

  /** User roles form control. */
  protected readonly userRolesControl = this.fb.control<UserRole[]>([]);

  public constructor(
    private readonly fb: NonNullableFormBuilder,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: UserRole[] | null): void {
    this.userRolesControl.setValue(value ?? [], { emitEvent: false });
    super.writeValue(value);
  }

  private subscribeToControlChanges(): void {
    this.userRolesControl.valueChanges.pipe(
      takeUntilDestroy(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }
}
