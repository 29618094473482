import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/** Custom icon data. */
interface IconData {

  /** Icon name. */
  readonly name: string;

  /** Path to icon. */
  readonly path: string;
}

/** Icon service.*/
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private readonly icons: IconData[] = [
    {
      name: 'components-light',
      path: 'assets/icons/components-light.svg',
    }, {
      name: 'organization-light',
      path: 'assets/icons/organization-light.svg',
    }, {
      name: 'refresh-active',
      path: 'assets/icons/refresh-active.svg',
    }, {
      name: 'port',
      path: 'assets/icons/port.svg',
    }, {
      name: 'templates-light',
      path: 'assets/icons/templates-light.svg',
    }, {
      name: 'user-light',
      path: 'assets/icons/user-light.svg',
    }, {
      name: 'diagram-system',
      path: 'assets/icons/diagram-system.svg',
    }, {
      name: 'diagram-floorplan',
      path: 'assets/icons/diagram-floorplan.svg',
    }, {
      name: 'diagram-riser',
      path: 'assets/icons/diagram-riser.svg',
    },
  ];

  public constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) { }

  /** Register icons. */
  public registerIcons(): void {
    this.icons.forEach(({ name, path }) => this.registerIcon(name, path));
  }

  private registerIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path),
    );
  }
}
