import { enumToArray } from '../utils/enum-to-array';

/** Project User Role. */
export enum ProjectUserRole {

  /** Engineer. */
  Engineer = 'Engineer',

  /** Technician. */
  Technician = 'Technician',

  /** CxEngineer. */
  CxEngineer = 'CxEngineer',
}

export namespace ProjectUserRole {
  const TITLE_MAP: Record<ProjectUserRole, string> = {
    [ProjectUserRole.CxEngineer]: 'Cx Engineer',
    [ProjectUserRole.Engineer]: 'Engineer',
    [ProjectUserRole.Technician]: 'Technician',
  };

  const DESCRIPTION_MAP: Record<ProjectUserRole, string> = {
    [ProjectUserRole.CxEngineer]: `Very limited access to projects that they are assigned to.
    Can access Diagrams, Pages, Drawing Packages. No access to Files, Schedules, RFIs. Can Save and Push projects.`,
    [ProjectUserRole.Technician]: 'Limited access to projects that they are assigned to.',
    [ProjectUserRole.Engineer]: 'Write access to projects they are assigned to.',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  export function toReadable(value: ProjectUserRole): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /**
   * Convert a certain enum value to description role text.
   * @param value Value of enum.
   */
  export function toDescription(value: ProjectUserRole): string {
    const readable = DESCRIPTION_MAP[value];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /** Get list of all enum items. */
  export function toArray(): ProjectUserRole[] {
    return enumToArray(ProjectUserRole);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: ProjectUserRole): ProjectUserRole {
    return item;
  }
}
