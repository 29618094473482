<mat-toolbar class="app-toolbar">
  <div class="app-toolbar__left-section">
    <button mat-icon-button type="button" (click)="onToggleSidebarOpened()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="app-toolbar__center-section">
    <ng-container *ngIf="user?.isGlobalAdmin; else organizations">
      <h1 class="organization-name" [title]="adminTitle">
        {{ adminTitle }}
      </h1>
    </ng-container>
    <ng-template #organizations>
      <mat-select
        class="organization-select"
        title="Select Organization"
        [value]="selectedOrganizationId$ | async"
        (selectionChange)="changeSelectedOrganization($event.value)"
      >
        <mat-option
          *ngFor="let org of organizations$ | async; trackBy: trackById"
          [value]="org.id"
          [title]="org.name"
        >
          {{ org.name }}
        </mat-option>
      </mat-select>
    </ng-template>
  </div>
  <div class="app-toolbar__right-section">
    <ng-container *ngIf="user">
      <button
        mat-button
        type="button"
        class="app-toolbar__profile-menu-btn"
        [matMenuTriggerFor]="menu"
      >
        <span class="profile-menu-btn__label">
          {{ user.firstName }} {{ user.lastName }}
          <mat-icon>arrow_drop_down</mat-icon>
        </span>
      </button>
      <mat-menu
        #menu="matMenu"
        xPosition="before"
        class="app-toolbar__profile-menu"
      >
        <button mat-menu-item type="button" (click)="onLogoutClick()">
          <mat-icon class="logout-icon">logout</mat-icon>
          Logout
        </button>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>
<mat-toolbar class="breadcrumbs">
  <xng-breadcrumb />
</mat-toolbar>
