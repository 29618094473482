import { ChangeDetectorRef, Directive, forwardRef, ForwardRefFn, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Create provider for a control.
 * @param factory Factory providing a reference to control component class.
 */
export function controlProviderFor(factory: ForwardRefFn): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(factory),
    multi: true,
  };
}

/**
 * Create validator provider for a control.
 * @param factory Factory providing a reference to control component class.
 */
export function validatorProviderFor(factory: ForwardRefFn): Provider {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(factory),
    multi: true,
  };
}

type OnChangeFn<V> = ((opt: V | null) => void);
type OnTouchedFn = () => void;
type OnDisabledFn = (disabled: boolean) => void;

/** Base implementation of Angular's control value accessor. */
// Angular requires some decorator so that DI would get things right, Directive is added to avoid specifying Component's metadata.
@Directive()
export abstract class BaseValueAccessor<T> implements ControlValueAccessor {
  /** Whether the control should be disabled. */
  @Input()
  public set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
    this.onDisabledFn(isDisabled);
  }

  /** Whether the control should be disabled. */
  public get disabled(): boolean {
    return this._disabled;
  }

  private _disabled = false;

  private isTouched = false;

  private onChangeFn: OnChangeFn<T> = () => undefined;

  private onTouchedFn: OnTouchedFn = () => undefined;

  private onDisabledFn: OnDisabledFn = () => undefined;

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
  ) { }

  /**
   * Saves the Angular's onChange callbacks so to call them whenever the value is changed.
   * @param fn Callback.
   */
  public registerOnChange(fn: OnChangeFn<T>): void {
    this.onChangeFn = fn;
  }

  /**
   * Saves onTouched callback which will be called once the value is changed.
   * @param fn Callback.
   */
  public registerOnTouched(fn: OnTouchedFn): void {
    this.onTouchedFn = fn;
  }

  /**
   * Saves onDisabled callback which will be called whenever the control is disabled/enabled.
   * @param fn Callback.
   */
  protected registerOnDisabled(fn: OnDisabledFn): void {
    this.onDisabledFn = fn;
  }

  /**
   * Called whenever the Angular's FormControl is set to be disabled.
   * @param isDisabled Disabled state.
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Emit change callbacks.
   * @param value Value to emit.
   */
  protected emitChange(value: T | null): void {
    if (!this.isTouched) {
      this.emitTouched();
    }
    this.onChangeFn(value);
    this.changeDetectorRef.markForCheck();
  }

  /** Emit touched. */
  protected emitTouched(): void {
    this.isTouched = true;
    this.onTouchedFn();
  }

  /** @inheritdoc */
  public writeValue(_value: T | null): void {
    // will optionally be implemented by the descendants.
  }
}

/**
 * Simple synchronous value accessor implementation. Has a synchronous `controlValue` property that contains a value.
 * Reduces boilerplate needed for implementing custom controls via
 * Angular's [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor).
 * @see ControlValueAccessor.
 */
// Angular requires some decorator so that DI would get things right, Directive is added to avoid specifying Component's metadata.
@Directive()
export class SimpleValueAccessor<T> extends BaseValueAccessor<T> {
  /** Control value. */
  public set controlValue(v: T | null) {
    this._value = v;
    this.emitChange(this._value);
  }

  /** Value. */
  public get controlValue(): T | null {
    return this._value ?? null;
  }

  private _value: T | null | undefined;

  public constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  /**
   * Used by Angular to write when assigned FormControl is changed.
   * @param value Value passed from the outside of value accessor by Angular's FormControl.
   * `null` when form is initialized without initial data.
   */
  public override writeValue(value: T | null): void {
    this._value = value;
    this.changeDetectorRef.markForCheck();
  }

  /** @inheritDoc */
  public override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    this.changeDetectorRef.markForCheck();
  }
}
