import { Injectable } from '@angular/core';
import { OrganizationApprovalFilters } from '@atsdart/common/core/models/filters/organization-approval-filters';
import { removeEmptyValues } from '@atsdart/common/core/utils/remove-empty-values';

import { OrganizationApprovalFiltersDto } from '../dto/filters/organization-approval-filters.dto';
import { IMapperToDto } from '../mappers';

/** Mapper for User organization approval filters. */
@Injectable({ providedIn: 'root' })
export class OrganizationApprovalFiltersMapper implements IMapperToDto<OrganizationApprovalFiltersDto, OrganizationApprovalFilters> {
  /** @inheritdoc */
  public toDto(data?: OrganizationApprovalFilters): OrganizationApprovalFiltersDto {
    const filtersDto: OrganizationApprovalFiltersDto = {
      UserId: data?.userId,
    };

    return removeEmptyValues(filtersDto);
  }
}
