import { ProjectUserRole } from '../enums/project-user-role';

/** Project user permission. */
export class ProjectUserPermission {
  /** Project id. */
  public readonly id: number;

  /** Roles. */
  public readonly roles: readonly ProjectUserRole[];

  public constructor(data: ProjectUserPermissionInitArgs) {
    this.id = data.id;
    this.roles = data.roles;
  }
}

type ProjectUserPermissionInitArgs = ProjectUserPermission;

/** Project user permissions edit. */
export class ProjectUserPermissionEdit {
  /** User id. */
  public readonly userId: number;

  /** Role. It's not required field. */
  public readonly role: ProjectUserRole | null;

  public constructor(data: ProjectUserPermissionsEditInitArgs) {
    this.userId = data.userId;
    this.role = data.role;
  }
}

type ProjectUserPermissionsEditInitArgs = ProjectUserPermissionEdit;

/** Project user profile permissions. */
export class ProjectUserProfilePermission extends ProjectUserPermission {
  /** Project name. */
  public readonly name: string;

  /** Organization id. */
  public readonly organizationId: number;

  /** Organization name. */
  public readonly organizationName: string;

  public constructor(data: ProjectUserProfilePermissionInitArgs) {
    super(data);
    this.name = data.name;
    this.organizationId = data.organizationId;
    this.organizationName = data.organizationName;
  }
}

type ProjectUserProfilePermissionInitArgs = ProjectUserProfilePermission;
