import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { UserEditForm } from '../../models/forms/user-edit-form';
import { OrganizationApproval } from '../../models/organization-approval';
import { OrganizationUserPermission } from '../../models/organization-user-permission';
import { EditUser, User } from '../../models/user';
import { OrganizationInvitation } from '../../models/organization-invitation';

import { DateMapper } from './date-mapper';
import { AuthValidationErrorDto } from './dto/auth-validation-error.dto';
import { CreateUserDto, EditUserDto } from './dto/edit-user.dto';
import { OrganizationApprovalDto } from './dto/organization-user-permissions.dto';
import { UserDto } from './dto/user.dto';
import { AppValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import {
  IMapperFromDto,
  IMapperToDto,
  IValidationErrorMapper,
} from './mappers';
import { OrganizationUserRolesMapper } from './organization-user-roles.mapper';

/** User entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserMapper
implements
  IMapperFromDto<UserDto, User>,
  IMapperToDto<EditUserDto, EditUser>,
  IValidationErrorMapper<EditUserDto, UserEditForm> {
  public constructor(
    private readonly dateMapper: DateMapper,
    private readonly userRolesMapper: OrganizationUserRolesMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: UserDto): User {
    return new User({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      organizationsNames: data.organizationsNames,
      isGlobalAdmin: data.isGlobalAdmin,
      createdAt: this.dateMapper.getDateWithoutTimezone(data.createdAt),
      userOrganizationPermissions: data.userOrganizationPermissions ? new OrganizationUserPermission({
        id: data.userOrganizationPermissions.organizationId,
        roles: this.userRolesMapper.fromDto(data.userOrganizationPermissions),
      }) : null,
      organizationApprovals: data.organizationApprovals.map(approval => this.fromOrganizationApprovalDto(approval)),
      invitation: data.invitation ? new OrganizationInvitation({
        id: data.invitation.organizationId,
        name: data.invitation.organizationName,
      }) : null,
    });
  }

  /**
   * Map organization approval dto.
   * @param data Organization approval dto.
   */
  public fromOrganizationApprovalDto(data: OrganizationApprovalDto): OrganizationApproval {
    return new OrganizationApproval({
      organizationId: data.organizationId,
      organizationName: data.organizationName,
      status: data.status,
    });
  }

  /** @inheritdoc */
  public toDto(data: EditUser): EditUserDto {
    const userOrganizations = data.organizations.map(organization => ({
      ...this.userRolesMapper.toDto(organization.roles),
      organizationId: organization.id,
    }));
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      userOrganizations,
    };
  }

  /** @inheritdoc */
  public toCreateDto(data: EditUser): CreateUserDto {
    const userOrganizations = data.organizations.map(organization => ({
      ...this.userRolesMapper.toDto(organization.roles),
      organizationId: organization.id,
    }));
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,

      // In request you can create a user for only one organization.
      userOrganizationDto: userOrganizations[0],
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: AppValidationErrorDto<EditUserDto & AuthValidationErrorDto>,
  ): EntityValidationErrors<UserEditForm> {
    return {
      lastName: extractErrorMessage(errorDto.lastName),
      firstName: extractErrorMessage(errorDto.firstName),
      email: extractErrorMessage(errorDto.email) ?? extractErrorMessage(errorDto.Email),
    };
  }
}
