import { ChangeDetectorRef, TemplateRef, ViewContainerRef } from '@angular/core';

/** Directive which allow to conditionally render content. */
export abstract class ConditionalRenderingDirective {
  protected constructor(
    protected readonly viewContainer: ViewContainerRef,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly templateRef: TemplateRef<unknown>,
  ) { }

  /** Render content. */
  protected renderContent(): void {
    this.viewContainer.clear();
    if (!this.templateRef) {
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
    this.changeDetector.markForCheck();
  }

  /** Remove content. */
  protected clearContent(): void {
    this.viewContainer.clear();
    this.changeDetector.markForCheck();
  }
}
