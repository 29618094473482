import { Injectable } from '@angular/core';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
  public constructor(
    private readonly appConfig: AppConfig,
  ) { }

  // Please prefer the following way of defining urls scope:
  // public readonly scope = {
  //   get: (id: string) => this.toApi('scope', id),
  //   list: this.toApi('scope/list/'),
  // };

  /** Auth-related routes. */
  public readonly auth = {
    login: this.toApi('auth'),
    refreshSecret: this.toApi('auth'),
    resetPassword: this.toApi('auth/forgot-password'),
    confirmPasswordReset: this.toApi('auth/reset-password'),
    confirmNewEmail: this.toApi('personal/confirm-new-email'),
  } as const;

  /** Routes for getting/editing current user's info. */
  public readonly userAuth = {
    currentProfile: this.toApi('auth'),
    changePassword: this.toApi('auth/forgot-password'),
  } as const;

  /** User registration related routes. */
  public readonly register = {
    register: this.toApi('register'),
    registerConfirm: this.toApi('register/confirm'),
    registerInvite: this.toApi('register/invite'),
    registerCompleteInvitation: this.toApi('register/complete-invitation'),
    registerResendInvitation: this.toApi('register/resend-invitation'),
    registerResendConfirm: this.toApi('register/resend-confirm'),
  } as const;

  /** Routes for getting/editing users. */
  public readonly usersApi = {
    getList: this.toApi('users'),
    getById: (id: number) => this.toApi('users', String(id)),
    restoreUserById: (id: number) => this.toApi('users', String(id), 'restore'),
  } as const;

  /** Routes for getting/editing organizations. */
  public readonly organizationsApi = {
    getList: this.toApi('organizations'),
    getById: (id: number) => this.toApi('organizations', String(id)),
    getUsersList: (id: number) => this.toApi('organizations', String(id), 'users'),
    getUserById: (id: number, userId: number) => this.toApi('organizations', String(id), 'users', String(userId)),
    createOrganization: this.toApi('organizations'),
    addUser: (id: number) => this.toApi('organizations', String(id), 'users'),
    updateOrganization: (id: number) => this.toApi('organizations', String(id)),
    deleteOrganization: (id: number) => this.toApi('organizations', String(id)),
    restoreOrganization: (id: number) => this.toApi('organizations', String(id), 'restore'),
    updateUserInOrganization: (id: number, userId: number) => this.toApi('organizations', String(id), 'users', String(userId)),
    updateUserOrganizationApproval: (id: number, userId: number) =>
      this.toApi('organizations', String(id), 'user-approval', String(userId)),
    getUserOrganizationApprovalById: (id: number) => this.toApi('organizations', String(id), 'user-approval'),
    updateOrganizationLogo: (organizationId: number) => this.toApi('organizations', String(organizationId), 'logo'),
    deleteOrganizationLogo: (organizationId: number) => this.toApi('organizations', String(organizationId), 'logo'),
  } as const;

  /** Routes for getting/editing organization component permissions. */
  public readonly organizationComponentPermissionsApi = {
    get: (organizationId: number) => this.toApi('organization-permissions', String(organizationId), 'search'),
    editPermission: (permissionId: number) => this.toApi('organization-permissions', 'permissions', String(permissionId)),
    deletePermission: (permissionId: number) => this.toApi('organization-permissions', 'permissions', String(permissionId)),
    createPermission: (organizationId: number) => this.toApi('organization-permissions', String(organizationId)),
  } as const;

  /** Routes for getting/editing organization component. */
  public readonly organizationComponentApi = {
    getComponents: (organizationId: number) => this.toApi('organization-permissions', String(organizationId), 'components'),
    create: this.toApi('library', 'components'),
    getById: (globalIdComponent: string) => this.toApi('library', 'components', globalIdComponent, 'info'),
    update: (globalIdComponent: string) => this.toApi('library', 'components', globalIdComponent),
    delete: (globalIdComponent: string) => this.toApi('library', 'components', globalIdComponent),
    deleteBatch: this.toApi('library', 'components', 'batch'),
    export: (organizationId: number) => this.toApi('organization-permissions', String(organizationId), 'components', 'export'),
    import: (organizationId: number) => this.toApi('organization-permissions', String(organizationId), 'components', 'import'),
  } as const;

  /** Routes for getting/editing component attachment. */
  public readonly componentAttachmentApi = {
    getAttachments: this.toApi('library', 'component-attachments'),
    createAndUploadFile: this.toApi('library', 'component-attachments', 'upload '),
    create: this.toApi('library', 'component-attachments'),
    uploadUrl: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId, 'upload-url'),
    confirmUploadFile: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId, 'confirm-upload'),
    downloadFile: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId),
    downloadFileUrl: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId, 'download-url'),
    update: (attachmentId: string) => this.toApi('library', 'component-attachments', attachmentId),
    delete: (componentId: string) => this.toApi('library', 'component-attachments', componentId),
  } as const;

  /** Routes for getting/editing projects. */
  public readonly projectsApi = {
    getList: this.toApi('projects'),
    getById: (id: number) => this.toApi('projects', String(id)),
    getUsers: (id: number) => this.toApi('projects', String(id), 'users'),
    getUsersById: (id: number, userId: number) => this.toApi('projects', String(id), 'users', String(userId)),
    getTemplates: this.toApi('projects/templates'),
    getTemplatesById: (id: number) => this.toApi('projects/templates', String(id)),
  } as const;

  /** Routes for getting/editing tutorials video. */
  public readonly tutorialsApi = {
    getCategoryList: this.toApi('tutorials/categories'),
    createCategory: this.toApi('tutorials/categories'),
    deleteCategory: (id: number) => this.toApi('tutorials/categories', String(id)),
    updateCategory: (id: number) => this.toApi('tutorials/categories', String(id)),
    getTutorialVideo: (id: number) => this.toApi('tutorials/videos', String(id)),
    updateTutorialVideo: (id: number) => this.toApi('tutorials/videos', String(id)),
    deleteTutorialVideo: (id: number) => this.toApi('tutorials/videos', String(id)),
    updateTutorialVideoOrder: (id: number) => this.toApi('tutorials/categories', String(id), 'videos/order-batch-update'),
    updateTutorialCategoryOrder: this.toApi('tutorials/categories/order-batch-update'),
    createTutorialVideo: this.toApi('tutorials/videos'),
  } as const;

  /** Routes for getting/editing projects. */
  public readonly portTypesApi = {
    getCategoriesList: this.toApi('custom-ports/categories'),
    createCategory: (organizationId: number) => this.toApi(`custom-ports/categories?organizationId=${organizationId}`),
    updateCategory: (id: number) => this.toApi('custom-ports/categories', String(id)),
    deleteCategory: (id: number) => this.toApi('custom-ports/categories', String(id)),
    getPortTypeById: (id: string) => this.toApi('custom-ports/configurations', id),
    createPortType: (categoryId: number) => this.toApi(`custom-ports/configurations?categoryId=${categoryId}`),
    updatePortType: (id: string) => this.toApi('custom-ports/configurations', id),
    deletePortType: (id: string) => this.toApi('custom-ports/configurations', id),
  } as const;

  /** Endpoints for tags API. */
  public readonly tagsApi = {
    getTags: this.toApi('library', 'tags', 'search'),
  } as const;

  /** Endpoints for labor tasks API. */
  public readonly laborTasksApi = {
    getList: this.toApi('sales', 'labor-tasks'),
  } as const;

  /** Endpoints for getting/editing sales project. */
  public readonly salesProjectApi = {
    getList: this.toApi('sales', 'base-estimates'),
    delete: (estimateId: number) => this.toApi('sales', 'base-estimates', String(estimateId)),
  } as const;

  /**
   * Checks whether the url is application-scoped.
   * @param url Url to check.
   */
  public isApplicationUrl(url: string): boolean {
    return url.startsWith(this.appConfig.apiUrl);
  }

  /**
   * Checks whether the specified url is calling an auth-related endpoint.
   * @param url Url to check.
   */
  public isAuthUrl(url: string): boolean {
    return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
  }

  private toApi(...args: readonly string[]): string {
    const path = args.join('/');
    return new URL(path, this.appConfig.apiUrl).toString();
  }
}
