import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DEFAULT_ORGANIZATION_LOGO } from '@atsdart/common/core/utils/constants';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';

/** Organization logo. */
@Component({
  selector: 'atsdartw-organization-logo',
  templateUrl: './organization-logo.component.html',
  styleUrls: ['./organization-logo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationLogoComponent {

  /** Image source path. */
  @Input()
  public set imageSourcePath(value: string) {
    if (value !== '') {
      this.imageSource$.next(value);
    }
  }

  private readonly domSanitizer = inject(DomSanitizer);

  private readonly imageSource$ = new BehaviorSubject(DEFAULT_ORGANIZATION_LOGO);

  /** Checked organization logo URL. */
  protected readonly checkedLogoUrl$ = this.createLogoImageUrlStream();

  private createLogoImageUrlStream(): Observable<SafeUrl> {
    return this.imageSource$.pipe(
      startWith(DEFAULT_ORGANIZATION_LOGO),
      map(checkedUrl => this.domSanitizer.bypassSecurityTrustUrl(checkedUrl)),
    );
  }

}
