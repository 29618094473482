import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { uniqBy } from 'lodash-es';
import { combineLatest, map, Observable } from 'rxjs';
import { UserAuthService } from '@atsdart/common/core/services/user-auth.service';
import { ToggleSubject } from '@atsdart/common/core/utils/rxjs/toggle-subject';
import { trackByIndex } from '@atsdart/common/core/utils/trackby';
import { UserRole } from '@atsdart/common/core/enums/user-role';
import { CurrentOrganizationService } from '@atsdart/common/core/services/current-organization.service';

import { NavItem } from '../nav-list-item/navigation-item';

import { navItemsForGlobalAdmin, navItemsForOrganizationAdmin, navItemsForUserAdmin, navItemsForUsersNotManageOrganization } from './nav-items';

/** Web app layout. */
@Component({
  selector: 'atsdartw-web-app-layout',
  templateUrl: './web-app-layout.component.html',
  styleUrls: ['./web-app-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebAppLayoutComponent {

  private readonly userService = inject(UserAuthService);

  private readonly currentOrganizationService = inject(CurrentOrganizationService);

  /** Current sidenav state. */
  protected readonly sidebarOpened$ = new ToggleSubject(true);

  /** Current user. */
  protected readonly user$ = this.userService.currentUser$;

  /** Navigation list items. */
  protected readonly navigationListItems$ = this.createNavItem();

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  private createNavItem(): Observable<NavItem[]> {
    return combineLatest([
      this.user$,
      this.currentOrganizationService.organization$,
    ]).pipe(
      map(([user, selectedOrganization]) => {
        if (user?.isGlobalAdmin) {
          return navItemsForGlobalAdmin;
        }
        const navItems = selectedOrganization?.roles.reduce<NavItem[]>((acc, role) => {
          if (role === UserRole.OrganizationAdmin) {
            return acc.concat(navItemsForOrganizationAdmin);
          }

          if (role === UserRole.UserAdmin) {
            return acc.concat(navItemsForUserAdmin);
          }

          if (UserRole.isOrganizationRole(role)) {
            return acc.concat(navItemsForUsersNotManageOrganization);
          }

          return acc;

        }, []) ?? [];
        return this.mergeDuplicatesNavItems(navItems);
      }),
    );
  }

  /**
   * Merge duplicates only for menu items on the first and second level.
   * @param navItems Nav items.
   */
  private mergeDuplicatesNavItems(navItems: readonly NavItem[]): NavItem[] {
    return navItems.reduce<NavItem[]>((acc, navItem) => {
      const existingItem = acc.find(i => i.path === navItem.path);
      if (existingItem == null) {
        const children = navItems.filter(item => item.path === navItem.path)
          .flatMap(i => i.children ?? []);
        const uniqueChildren = uniqBy(children, item => item.path);
        acc.push({ ...navItem, children: uniqueChildren });
      }

      return acc;
    }, []);
  }
}
