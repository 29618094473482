import { Injectable } from '@angular/core';

import { EditUserOrganizationApproval, UserOrganizationApproval } from '../../models/user-organization-approval';
import { UserOrganizationApprovalForm } from '../../models/forms/user-organization-approval-form';
import { EntityValidationErrors } from '../../models/app-error';

import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';
import { OrganizationUserRolesMapper } from './organization-user-roles.mapper';
import { AppValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { EditUserOrganizationApprovalDto } from './dto/edit-organization-user-approval.dto';
import { UserOrganizationApprovalDto } from './dto/organization-user-approval.dto';

/** User organization approval mapper. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationUserApprovalMapper
implements
  IMapperFromDto<UserOrganizationApprovalDto, UserOrganizationApproval>,
  IMapperToDto<EditUserOrganizationApprovalDto, EditUserOrganizationApproval>,
  IValidationErrorMapper<EditUserOrganizationApprovalDto, UserOrganizationApprovalForm> {
  public constructor(
    private readonly organizationUserRoleMapper: OrganizationUserRolesMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: UserOrganizationApprovalDto): UserOrganizationApproval {
    return {
      approvalId: data.id,
      userId: data.userId,
      status: data.status,
    };
  }

  /** @inheritdoc */
  public toDto(data: EditUserOrganizationApproval): EditUserOrganizationApprovalDto {
    return {
      status: data.status,
      permissions: this.organizationUserRoleMapper.toDto(data.userRoles),
      comment: '',
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: AppValidationErrorDto<EditUserOrganizationApprovalDto>,
  ): EntityValidationErrors<UserOrganizationApprovalForm> {
    return {
      status: extractErrorMessage(errorDto.status),
      organizationRoles: extractErrorMessage(errorDto.permissions),
      projectRole: extractErrorMessage(errorDto.permissions) ?? extractErrorMessage(errorDto.non_field_errors),
    };
  }
}
