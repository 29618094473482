<div class="error-card">
  <h1 class="error-card__header">Access Denied!</h1>
  <p class="error-card__body">
    You don't have permissions to access Admin Panel.
  </p>

  <footer class="error-card__footer">
    <button mat-button type="button" (click)="onLogoutClick()">Logout</button>
  </footer>
</div>
