<form
  *ngIf="filtersForm"
  class="filters-form"
  [formGroup]="filtersForm"
  (submit)="onSubmitForm()"
>
  <label class="filters-form-control">
    <span class="filters-form-control__label">Name</span>
    <mat-form-field class="filters-form-control__field no-padding">
      <input
        matInput
        type="text"
        formControlName="firstOrLastName"
        placeholder="Name"
      />
      <button
        *ngIf="filtersForm.controls.firstOrLastName?.value"
        mat-icon-button
        matSuffix
        type="button"
        (click)="onClearText('firstOrLastName')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </label>
  <label class="filters-form-control">
    <span class="filters-form-control__label">Email</span>
    <mat-form-field class="filters-form-control__field no-padding">
      <input
        matInput
        type="text"
        formControlName="email"
        placeholder="email@address.com"
      />
      <button
        *ngIf="filtersForm.controls.email?.value"
        type="button"
        mat-icon-button
        matSuffix
        (click)="onClearText('email')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </label>
  <button
    mat-flat-button
    color="primary"
    class="filters-form__submit-btn"
    type="submit"
    [atsdartcLoading]="isLoading"
  >
    Search
  </button>
</form>
