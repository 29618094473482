import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogData } from './confirm-dialog-data';

/** Confirmation dialog component. */
@Component({
  selector: 'atsdartw-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  /** Title dialog. */
  protected readonly titleDialog = this.data.title;

  /** Info dialog. */
  protected readonly infoDialog = this.data.text;

  /** Confirm button name. */
  protected readonly confirmButtonText = this.data.confirmButtonText;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: ConfirmDialogData,
  ) {}
}
