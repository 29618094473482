<mat-sidenav-container
  *ngLet="sidebarOpened$ | async as sidebarOpened"
  autosize
>
  <mat-sidenav
    opened
    mode="side"
    class="app-sidebar"
    [class.mini]="!sidebarOpened"
  >
    <div
      class="app-sidebar__top-section"
      [class.expanded]="sidebarOpened"
    ></div>
    <mat-nav-list class="app-sidebar__navigation">
      <atsdartw-nav-list-item
        *ngFor="let item of navigationListItems$ | async; trackBy: trackByIndex"
        [item]="item"
        [collapsed]="!sidebarOpened"
      />
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content
    class="app-content"
    [class.sidebar-mini]="!sidebarOpened"
    [style]="'margin-left: none'"
  >
    <atsdartw-app-toolbar
      [user]="user$ | async"
      (sidebarChanged)="sidebarOpened$.toggle()"
    />
    <main class="app-page-content">
      <router-outlet />
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
