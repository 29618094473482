<ng-container *ngLet="isOpened$ | async as isOpened">
  <div
    *ngIf="isOpened"
    class="backdrop"
    @backdropTrigger
    (@backdropTrigger.done)="redirectOnClose(isOpened)"
    (click)="onClickBackground()"
  ></div>
  <div *ngIf="isOpened" class="panel" @panelTrigger>
    <div class="header">
      <h2 class="header__title title-1 ellipsis" [title]="headerTitle">
        {{ headerTitle }}
      </h2>
      <button mat-icon-button type="button" (click)="closeSidePanel()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
    <div class="content" cdkScrollable>
      <ng-content select="[panelContent]" />
    </div>
    <ng-content select="[panelFooter]" />
  </div>
</ng-container>
