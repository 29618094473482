import { StrictOmit } from '../utils/types/strict-omit';

/** Organization. */
export class Organization {

  /** Organization id. */
  public readonly id: number;

  /** Organization name. */
  public readonly name: string;

  /** City of location of the organization. */
  public readonly city: string;

  /** State of location of the organization. */
  public readonly state: string;

  /** Country of location of the organization. */
  public readonly country: string;

  /** Organization phone. */
  public readonly phone: string;

  /** Organization's address. */
  public readonly address: string;

  /** Zip. */
  public readonly zip: string;

  /** Organization's fax. */
  public readonly fax: string;

  /** Organization's mailing address. */
  public readonly mailingAddress: string;

  /** Organization's mailing city. */
  public readonly mailingCity: string;

  /** Organization's mailing state. */
  public readonly mailingState: string;

  /** Organization's mailing zip. */
  public readonly mailingZip: string;

  /** Organization's primary email. */
  public readonly primaryEmail: string;

  /** Organization is active. */
  public readonly isActive: boolean;

  /** Logo URL. */
  public readonly logoUrl: string;

  public constructor(data: OrganizationInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.city = data.city;
    this.country = data.country;
    this.state = data.state;
    this.phone = data.phone;
    this.address = data.address;
    this.zip = data.zip;
    this.fax = data.fax;
    this.mailingAddress = data.mailingAddress;
    this.mailingCity = data.mailingCity;
    this.mailingState = data.mailingState;
    this.mailingZip = data.mailingZip;
    this.primaryEmail = data.primaryEmail;
    this.isActive = data.isActive;
    this.logoUrl = data.logoUrl;
  }
}

type OrganizationInitArgs = Organization;

/** Edit organization. */
export type EditOrganization = StrictOmit<Organization, 'id' | 'isActive' | 'logoUrl'>;

/** Create organization. */
export type CreateOrganization = EditOrganization & Readonly<{ logoImageFile?: File; }>;
