<input
  matInput
  readonly
  class="file-upload__input"
  [value]="controlValue?.name"
  (click)="inputUploadingFile.click()"
/>
<button
  mat-flat-button
  type="button"
  color="primary"
  class="file-upload__button"
  (click)="inputUploadingFile.click()"
>
  Upload File
</button>
<input
  #inputUploadingFile
  type="file"
  class="form-field__input-file"
  [accept]="allowedFileTypes"
  (change)="onFileChange($event)"
/>
