import { enumToArray } from '../utils/enum-to-array';

/** User statuses. */
export enum UserStatus {

  /** Active. */
  Active = 'Active',

  /** Invited. */
  Invited = 'Invited',

  /** Approval Pending. */
  ApprovalPending = 'ApprovalPending',

  /** Disabled. */
  Disabled = 'Disabled',
}

export namespace UserStatus {
  const TITLE_MAP: Record<UserStatus, string> = {
    [UserStatus.Active]: 'Active',
    [UserStatus.Invited]: 'Invited',
    [UserStatus.ApprovalPending]: 'Approval Pending',
    [UserStatus.Disabled]: 'Disabled',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  export function toReadable(value: UserStatus): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      return '';
    }
    return readable;
  }

  /** Get list of all enum items. */
  export function toArray(): UserStatus[] {
    return enumToArray(UserStatus);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: UserStatus): UserStatus {
    return item;
  }
}
