import { UserRole } from '../enums/user-role';

/** Organization user permission. */
export class OrganizationUserPermission {
  /** Organization id. */
  public readonly id: number;

  /** Roles. */
  public readonly roles: readonly UserRole[];

  public constructor(data: OrganizationUserPermissionInitArgs) {
    this.id = data.id;
    this.roles = data.roles;
  }
}

type OrganizationUserPermissionInitArgs = OrganizationUserPermission;

/** Organization user profile permission. */
export class OrganizationUserProfilePermission extends OrganizationUserPermission {
  /** Organization name. */
  public readonly name: string;

  public constructor(data: OrganizationUserProfilePermissionInitArgs) {
    super(data);
    this.name = data.name;
  }
}

type OrganizationUserProfilePermissionInitArgs = OrganizationUserProfilePermission;
