import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UserAuthService } from '../services/user-auth.service';
import { routePaths } from '../utils/route-paths';

/** Guard prevents user from accessing if a user is not logged in. */
@Injectable({
  providedIn: 'root',
})
export class UnauthorizedGuard implements CanActivate, CanLoad {
  public constructor(
    private readonly userService: UserAuthService,
    private readonly router: Router,
  ) {}

  /** @inheritdoc */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  /**
   * Determine if route could be achieved.
   * @param _route Route.
   * @param state State.
   */
  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canNavigate(state);
  }

  private canNavigate(state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.userService.isAuthorized$.pipe(
      map(isAuthorized => this.guard(isAuthorized, state?.url)),
      first(),
    );
  }

  private guard(isAuthenticated: boolean, url?: string): boolean | UrlTree {
    if (isAuthenticated) {
      return true;
    }

    return this.router.createUrlTree(routePaths.login, {
      queryParams: url ? { next: url } : undefined,
    });
  }
}
