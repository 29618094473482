/**
 * Entity validation errors type.
 * Describes validation items for target entity.
 */
export type EntityValidationErrors<T> = {

  /** Error message for certain entity property. */
  [P in keyof T]?: PropValidationMessage<T[P]>;
};

/**
 * Validation message type for specific property type.
 * Could be a just error message for simple field or nested validation error for composite fields.
 */
export type PropValidationMessage<T> = T extends readonly (infer K)[]
  ? EntityValidationErrors<K>[]
  : T extends Record<string, unknown>
    ? EntityValidationErrors<T>
    : string;

/**
 * An error that was raised by the application intentionally.
 */
export class AppError<TEntity = unknown> extends Error {
  /** Error message. */
  public override readonly message: string;

  /** Validation errors for entity fields. */
  public readonly validationData?: EntityValidationErrors<TEntity>;

  public constructor(message: string, validationData?: EntityValidationErrors<TEntity>) {
    super(message);
    this.message = message;
    this.validationData = validationData;
  }
}
