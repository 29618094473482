<div class="confirmation-card">
  <div
    *ngIf="confirmError as error; else showConfirmation"
    class="confirmation-card__error"
  >
    {{ error }}
  </div>
</div>

<ng-template #showConfirmation>
  <h1 class="confirmation-card__heading">{{ title }}</h1>
  <ng-container *ngIf="confirmResult as result; else showLoading">
    <div class="confirmation-card__icon-container">
      <mat-icon class="confirmation-card__icon">done</mat-icon>
    </div>
    <p class="confirmation-card__content">
      {{ result }}
    </p>
  </ng-container>
</ng-template>

<ng-template #showLoading>
  <div class="loader" [atsdartcLoading]="true"></div>
</ng-template>
