<mat-radio-group [formControl]="userRoleControl">
  <mat-radio-button
    *ngFor="let role of options; trackBy: userRole.trackBy"
    class="option"
    [value]="role"
  >
    <div class="option-content">
      {{ userRole.toReadable(role) }}
      <div
        class="option-description line-clamp"
        matTooltipClass="role-description-tooltip"
        [matTooltip]="userRole.getRoleDescription(role)"
      >
        {{ userRole.getRoleDescription(role) }}
      </div>
    </div>
  </mat-radio-button>
</mat-radio-group>
