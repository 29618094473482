<atsdartc-base-dialog class="dialog" dialogTitle="Download">
  <div class="dialog-content">
    <span class="dialog-content__text">
      If the download does not start automatically, click on the 'Download'
      button.
    </span>
    <a
      *ngIf="filename; else downloadLinkWithoutFilename"
      mat-flat-button
      color="primary"
      [download]="filename"
      [href]="downloadUrl"
    >
      Download
    </a>
  </div>
  <div class="buttons-content" dialogFooter>
    <button mat-stroked-button mat-dialog-close type="button" mat-dialog-close>
      Close
    </button>
  </div>
</atsdartc-base-dialog>
<ng-template #downloadLinkWithoutFilename>
  <a mat-flat-button download color="primary" [href]="downloadUrl">
    Download
  </a>
</ng-template>
