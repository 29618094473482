import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '@atsdart/common/core/services/user-auth.service';
import { routePaths } from '@atsdart/common/core/utils/route-paths';
import { first } from 'rxjs';

/** App access error page. */
@Component({
  selector: 'atsdartw-app-access-error-page',
  templateUrl: './app-access-error-page.component.html',
  styleUrls: ['./app-access-error-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppAccessErrorPageComponent {

  public constructor(
    private readonly userService: UserAuthService,
    private readonly router: Router,
  ) { }

  /** Handle click on logout button. */
  protected onLogoutClick(): void {
    this.userService.logout()
      .pipe(first())
      .subscribe(() => this.router.navigate(routePaths.login));
  }
}
