import { Injectable } from '@angular/core';

import { ProjectUserRole } from '../../enums/project-user-role';

import { ProjectUserPermissionFlagsDto } from './dto/project-user-permissions.dto';
import { IMapperFromDto } from './mappers';

const flagsToProjectUserRoleMap: Record<keyof ProjectUserPermissionFlagsDto, ProjectUserRole> = {
  isCxEngineer: ProjectUserRole.CxEngineer,
  isEngineer: ProjectUserRole.Engineer,
  isTechnician: ProjectUserRole.Technician,
};

/** Mapper for Project User Roles. */
@Injectable({
  providedIn: 'root',
})
export class ProjectUserRolesMapper implements IMapperFromDto<ProjectUserPermissionFlagsDto, ProjectUserRole[]> {

  /** @inheritdoc */
  public fromDto(dto: ProjectUserPermissionFlagsDto): ProjectUserRole[] {
    return Object.entries(dto)
      .map(([key, hasRole]) => {
        const role = flagsToProjectUserRoleMap[key as keyof ProjectUserPermissionFlagsDto];
        return hasRole === true ? role : undefined;
      })
      .filter((item): item is ProjectUserRole => item !== undefined);
  }

  /**
   * Map to project user permission flags DTO.
   * @param role Project user role.
   */
  public toProjectUserPermissionFlagsDto(role: ProjectUserRole | null): ProjectUserPermissionFlagsDto {
    return {
      isEngineer: role === ProjectUserRole.Engineer,
      isTechnician: role === ProjectUserRole.Technician,
      isCxEngineer: role === ProjectUserRole.CxEngineer,
    };
  }
}
