import { OrganizationApproval } from './organization-approval';
import { OrganizationInvitation } from './organization-invitation';
import { OrganizationUserPermission } from './organization-user-permission';
import { UserBase } from './user-base';

/** User model. */
export class User extends UserBase {

  /** Is user Global Admin. */
  public readonly isGlobalAdmin: boolean;

  /** Organizations names list. */
  public readonly organizationsNames: readonly string[];

  /**
   * Information about permissions in the organization of this user.
   * Note: might be 'null' if Organization permissions are not included in server response.
   */
  public readonly userOrganizationPermissions: OrganizationUserPermission | null;

  /** Created date. */
  public readonly createdAt: Date | null;

  /** Organization approvals. */
  public readonly organizationApprovals: readonly OrganizationApproval[];

  /** Organization invitation. */
  public readonly invitation: OrganizationInvitation | null;

  public constructor(data: UserInitArgs) {
    super(data);
    this.isGlobalAdmin = data.isGlobalAdmin;
    this.organizationsNames = data.organizationsNames;
    this.createdAt = data.createdAt;
    this.userOrganizationPermissions = data.userOrganizationPermissions;
    this.organizationApprovals = data.organizationApprovals;
    this.invitation = data.invitation;
  }
}

type UserInitArgs = User;

/** User model for create/edit of the entity. */
export interface EditUser {

  /** User's first name. */
  readonly firstName: string;

  /** User's last name. */
  readonly lastName: string;

  /** User's email. */
  readonly email: string;

  /** Organizations that this user belongs to. */
  readonly organizations: OrganizationUserPermission[];
}
