<div class="dialog">
  <div class="dialog__header">
    <h4 class="dialog__title">{{ titleDialog }}</h4>
    <button mat-icon-button mat-dialog-close type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dialog__content">
    <ng-content />
  </div>

  <div class="dialog__footer">
    <ng-content select="[dialogFooter]" />
  </div>
</div>
