import { trigger, transition, style, animate } from '@angular/animations';

/** Backdrop animation trigger. */
export const backdropAnimationTrigger = trigger('backdropTrigger', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
]);

/** Panel animation trigger. */
export const panelAnimationTrigger = trigger('panelTrigger', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('300ms ease', style({ transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [
    animate('300ms ease',
      style({ transform: 'translateX(100%)' })),
  ]),
]);
