import { Injectable } from '@angular/core';
import { OrganizationFilters } from '@atsdart/common/core/models/filters/organization-filter';
import { removeEmptyValues } from '@atsdart/common/core/utils/remove-empty-values';

import { OrganizationFiltersDto } from '../dto/filters/organization-filters.dto';
import { IMapperToDto } from '../mappers';

/** Mapper for organization filters. */
@Injectable({ providedIn: 'root' })
export class OrganizationFiltersMapper implements IMapperToDto<OrganizationFiltersDto, OrganizationFilters> {
  /** @inheritdoc */
  public toDto(data?: OrganizationFilters): OrganizationFiltersDto {
    const filtersDto: OrganizationFiltersDto = {
      NameContains: data?.organizationName,
      PrimaryEmailContains: data?.email,
      Status: data?.organizationStatus,
    };

    return removeEmptyValues(filtersDto);
  }
}
