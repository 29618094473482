import { Injectable } from '@angular/core';

import { UserProfile } from '../../models/user-profile';

import { IMapperFromDto } from './mappers';
import { UserProfileDto } from './dto/user-profile.dto';
import { OrganizationUserProfilePermissionsMapper } from './organization-user-profile-permissions.mapper';
import { ProjectUserProfilePermissionsMapper } from './project-user-profile-permissions.mapper';
import { UserMapper } from './user.mapper';

/** User profile mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserProfileMapper implements IMapperFromDto<UserProfileDto, UserProfile> {
  public constructor(
    private readonly organizationUserProfilePermissionsMapper: OrganizationUserProfilePermissionsMapper,
    private readonly projectUserProfilePermissionsMapper: ProjectUserProfilePermissionsMapper,
    private readonly userMapper: UserMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: UserProfileDto): UserProfile {
    return new UserProfile({
      id: dto.id,
      email: dto.email,
      firstName: dto.firstName,
      lastName: dto.lastName,
      isGlobalAdmin: dto.isGlobalAdmin,
      organizations: dto.organizations.map(org => this.organizationUserProfilePermissionsMapper.fromDto(org)),
      projects: dto.projects.map(project => this.projectUserProfilePermissionsMapper.fromDto(project)),
      organizationApprovals: dto.organizationApprovals.map(org => this.userMapper.fromOrganizationApprovalDto(org)),
    });
  }
}
