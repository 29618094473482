import { Injectable } from '@angular/core';
import { UserStatus } from '@atsdart/common/core/enums/user-status';
import { UserStatusFilter } from '@atsdart/common/core/enums/user-status-filter';

import { UserFilters } from '@atsdart/common/core/models/filters/user-filters';
import { removeEmptyValues } from '@atsdart/common/core/utils/remove-empty-values';

import { UserFiltersDto } from '../dto/filters/user-filters.dto';
import { IMapperToDto } from '../mappers';

/** Mapper for User filters. */
@Injectable({ providedIn: 'root' })
export class UserFiltersMapper implements IMapperToDto<UserFiltersDto, UserFilters> {
  /** @inheritdoc */
  public toDto(data?: UserFilters): UserFiltersDto {
    const filtersDto: UserFiltersDto = {
      OrganizationId: data?.organizationId,
      ProjectIdToGetAccessData: data?.projectIdToGetAccessData,
      NameContains: data?.firstOrLastName,
      EmailContains: data?.email,
      OrganizationIdToGetPermissions: data?.organizationIdToGetPermissions,
      IsEmailConfirmed: this.getIsEmailConfirmed(data?.userStatus),
      HaveRejectedOrPendingOrganizationApproval: this.getHaveRejectedOrPendingOrganizationApproval(data?.userStatus),
      Status: data?.userStatus === UserStatus.Disabled ? UserStatusFilter.Removed : UserStatusFilter.Active,
    };

    return removeEmptyValues(filtersDto);
  }

  private getIsEmailConfirmed(userStatus?: UserStatus): boolean | undefined {
    switch (userStatus) {
      case UserStatus.Active:
        return true;
      case UserStatus.Invited:
        return false;
      default:
        return undefined;
    }
  }

  private getHaveRejectedOrPendingOrganizationApproval(userStatus?: UserStatus): boolean | undefined {
    switch (userStatus) {
      case UserStatus.ApprovalPending:
        return true;
      default:
        return undefined;
    }
  }
}
