import { Observable, defer } from 'rxjs';

/**
 * Get checked image URL.
 * @param url URL.
 * @returns Valid URL or empty string if URL is invalid.
 */
export function getCheckedImageUrl(url: string): Observable<string> {
  const image = new Image();
  image.src = url;

  const promise = new Promise<string>((resolve, reject) => {
    image.onload = () => resolve(url);
    image.onerror = () => reject(new Error('Image is broken'));
  });

  return defer(() => promise);
}
